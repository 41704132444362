import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ReportListActions } from './report-list.actions';
import { ApiService } from '../shared/api.service';
import { BackgroundRequestActions } from '../background-request/background-request.actions';
import { isNil, not, prop, propEq } from '@qld-recreational/ramda';
import {
  ClaimCatchBackgroundRequest,
  ReportBackgroundRequest,
} from '../background-request/background-request.model';
import { equals } from 'rambda';
import { authSuccess } from '../auth/auth.actions';
import { from, of } from 'rxjs';
import { ReportListService } from './report-list.service';
import { Store } from '@ngrx/store';
import { selectUserID } from '../auth/auth.reducer';

@Injectable()
export class ReportListEffects {
  loadReportLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportListActions.loadReportLists),
      withLatestFrom(this.store.select(selectUserID)),
      switchMap(([, userId]) =>
        userId
          ? this.apiService.loadReports().pipe(
              switchMap((reports) =>
                Promise.all(
                  reports.map((report) =>
                    this.reportListService.transformReport(report)
                  )
                )
              ),
              map((reports) =>
                ReportListActions.loadReportListsSuccess({
                  reports,
                })
              )
            )
          : of(
              ReportListActions.loadReportListsFailure({
                error: 'User not logged in',
              })
            )
      )
    );
  });

  updateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.submitBackgroundRequestFailure),
      filter(
        ({ backgroundRequest }) =>
          equals('reports', backgroundRequest.changes.path) &&
          not(isNil(backgroundRequest.changes.id))
      ),
      map(({ backgroundRequest }) =>
        ReportListActions.updateReport({
          oldID: backgroundRequest.id,
          newID: backgroundRequest.changes.id,
        })
      )
    )
  );

  addReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.addBackgroundRequest),
      map(prop('backgroundRequest')),
      filter(
        (backgroundRequest): backgroundRequest is ReportBackgroundRequest =>
          propEq('path', 'reports', backgroundRequest)
      ),
      switchMap((backgroundRequest) =>
        from(
          this.reportListService.transformReport({
            ...backgroundRequest.payload,
            clientRequestID: backgroundRequest.id,
            reportID: -1,
            success: true,
          })
        ).pipe(
          map((report) => {
            return ReportListActions.addReport({
              report: {
                ...report,
                submitting: true,
              },
            });
          })
        )
      )
    )
  );

  updateClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.addBackgroundRequest),
      map(prop('backgroundRequest')),
      filter(
        (backgroundRequest): backgroundRequest is ClaimCatchBackgroundRequest =>
          propEq('path', 'claims', backgroundRequest)
      ),
      map((backgroundRequest) =>
        ReportListActions.updateReportClaim({
          claim: backgroundRequest.payload,
        })
      )
    )
  );

  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSuccess),
      map(() => ReportListActions.loadReportLists())
    )
  );

  public submitBackgroundRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.submitBackgroundRequestSuccess),
      filter(propEq('path', 'reports')),
      map(() => ReportListActions.loadReportLists())
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private reportListService: ReportListService,
    private store: Store
  ) {}
}
