export function ramda(): string {
  return 'ramda';
}

/**
 * rambda vs ramda
 * rambda is simply faster than ramda.
 * So whatever the function you find in rambda use it, otherwise use ramda
 * If you list in RB then if there is no function, then typescript will complain for you.
 * https://github.com/selfrefactor/rambda#benchmark
 */
import * as RB from 'rambda';
import * as R from 'ramda';

export const {
  and,
  all,
  or,
  lensProp,
  lensPath,
  view,
  add,
  where,
  set,
  over,
  identity,
  forEach,
  compose,
  when,
  anyPass,
  not,
  propEq,
  join,
  keys,
  ifElse,
  intersection,
  difference,
  differenceWith,
  curry,
  reverse,
  propOr,
  uniq,
  uniqBy,
  pickBy,
  comparator,
  gt,
  gte,
  lt,
  sort,
  eqProps,
  groupWith,
  clone,
  indexBy,
  chain,
  map,
  partition,
  pipe,
  applySpec,
  juxt,
  mergeAll,
  props,
  sortWith,
  ascend,
  mapObjIndexed,
  descend,
  __,
  trim,
  without,
  append,
  zipObj,
} = R;

export const {
  isNil,
  is,
  prop,
  path,
  pathOr,
  equals,
  has,
  isEmpty,
  both,
  either,
  multiply,
  toPairs,
  fromPairs,
  type,
  find,
  complement,
  flatten,
  defaultTo,
  range,
  groupBy,
  toLower,
  concat,
  sum,
  sortBy,
  filter,
  reduce,
  replace,
  flip,
  pluck,
  adjust,
  negate,
  assoc,
  dissoc,
  pick,
  omit,
  values,
  // tslint:disable-next-line: variable-name
  any,
  match,
  always,
  includes,
  startsWith,
  allPass,
  split,
  takeLast,
  take,
  merge,
  head,
  last,
  length,
  drop,
  dropLast,
  endsWith,
  toUpper,
} = RB;

export const createLensProp = (val: string) => {
  // @ts-ignore
  return lensProp(val);
};

export const toNumber = (val: string): number => {
  return isNilOrEmpty(val) ? null : +val;
};

export const isStrInteger = (val: string): boolean => {
  return /^\d+$/.test(val);
};

const isStringNullOrNil = (val: string): boolean =>
  equals('null', val) || equals('undefined', val) || isNil(val);
export const isNilOrEmpty = either(isStringNullOrNil, isEmpty);
export const propOrZero = propOr(0);

export const uniqBySpeciesId = uniqBy(prop('speciesId'));

// https://github.com/ramda/ramda/wiki/Cookbook#sql-style-joins
// tslint:disable-next-line: max-func-args
export const joinInner = curry((field1, field2, table1, table2) => {
  const indexed = indexBy(field1, table1);

  return chain((t2row) => {
    const corresponding = indexed[field2(t2row)];

    // @ts-ignore
    return corresponding ? [merge(t2row, corresponding)] : [];
  }, table2);
});

// tslint:disable-next-line: max-func-args
export const joinRight = R.curry((mapper1, mapper2, t1, t2) => {
  const indexed = R.indexBy(mapper1, t1);

  // @ts-ignore
  return t2.map((t2row) => merge(t2row, indexed[mapper2(t2row)]));
});

// tslint:disable-next-line: max-func-args
export const joinOuter = curry((field1, field2, table1, table2) => {
  const o1 = indexBy(field1, table1);
  const o2 = indexBy(field2, table2);

  return values(R.mergeWith(merge, o1, o2));
});

// tslint:disable-next-line: max-func-args
export const joinLeft = R.curry((f1, f2, t1, t2) => joinRight(f2, f1, t2, t1));

// https://github.com/ramda/ramda/wiki/Cookbook#sort-a-list-by-array-of-props-if-first-prop-equivalent-sort-by-second-etc
const firstTruthy = ([h, ...tail]) => reduce(either, h, tail);
const makeComparator = (propName: string) =>
  // @ts-ignore
  comparator((a, b) => lt(prop(propName, a), prop(propName, b)));
export const sortByProps = curry((args: string[], list) =>
  // @ts-ignore
  sort(firstTruthy(map(makeComparator, args)), list)
);

export const concatFlipped = flip(concat);
