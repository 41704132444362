import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { BackgroundRequestEffects } from './background-request.effects';
import { StoreModule } from '@ngrx/store';
import {
  backgroundRequestFeatureKey,
  backgroundRequestReducer,
} from './background-request.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([BackgroundRequestEffects]),
    StoreModule.forFeature(
      backgroundRequestFeatureKey,
      backgroundRequestReducer
    ),
  ],
})
export class BackgroundRequestModule {}
