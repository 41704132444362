import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { storageSync } from './ngrx-ionic-storage-sync';

// tslint:disable-next-line:no-empty-interface
export interface State {} // localStorageSync

export const ionicStorageSyncReducer = storageSync({
  hydratedStateKey: 'hydrated', // Add this key to the state
});

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [ionicStorageSyncReducer]
  : [ionicStorageSyncReducer];
