import {
  Asset,
  ChainModifiers,
  DeletedAsset,
  DeletedEntry,
  Entry,
  EntryCollection,
  EntryFieldTypes,
  EntrySkeletonType,
  FieldsType,
  SyncCollection,
} from 'contentful';
import { JsonObject } from 'type-fest';
import { IReportShareField } from '../shared/share-report-modal/share-report.model';

export enum ContentfulContext {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface ContentfulApi {
  name: string;
  space: string;
  host: string;
  accessToken: string;
}
export const contentfulApis: { [name: string]: ContentfulApi } = {
  [ContentfulContext.PRODUCTION]: {
    name: ContentfulContext.PRODUCTION,
    space: 'r9p6j404vvxj',
    accessToken: 'YIQiff5e0o9rcnhCsTT4SGBDSBHNMrBKbHZNYVeQ-Bk',
    host: 'cdn.contentful.com',
  },
  [ContentfulContext.STAGING]: {
    name: ContentfulContext.STAGING,
    space: 'r9p6j404vvxj',
    accessToken: 'BAMpIe91nVvL6WTuHVuv6ryscNWTXuVWy39bel2fJnQ',
    host: 'preview.contentful.com',
  },
};
export enum ContentfulContentType {
  species = 'speciesPage',
}

export enum ContentfulEntries {
  HomePage = '6wWK8bxvEuXoonMDPY0m97',
  SideMenu = '3JghDJxLlDZz7jC64NVBfH',
  SpeciesSummary = '3bVJaP38AJHVZr75G6g6cp',
  RulesContent = '3peiEgKQPOyz88zHMr2B7',
  DetailsContent = 'FySXJPAlOIMddznSGiqto',
  ContactFisheries = '7f0fZLacTeCbYvFhAVzQCu',
  KnowTheRules = '6O3FYNdc8R3CwwK1Ex2tiw',
  CanIFishHere = '40hOhkdNl2ZlGZMBZX4olW',
  TrainedFish = '7lLLwouVPOiDTT8IsxbOVu',
  WhichFishIsThis = '48srm0bPMzBZkXARa0asEv',
  AppInfo = '6USOYFpf38bk5abUek2pFp',
  ReportingMetadata = '3XQR3S9HNYxVOuzUGuuB24',
}

export type ISyncCollection = SyncCollection<
  EntrySkeletonType<FieldsType, string>,
  ChainModifiers,
  string
> &
  Stringifiable;

export interface IHomeFields {
  appTitle: EntryFieldTypes.Text;
  appSubTitle: EntryFieldTypes.Text;
  appSections: IContentfulEntryArrayField<IAppSectionFields>;
  heroImage: EntryFieldTypes.AssetLink;
  localSpeciesLabel: EntryFieldTypes.Text;
  searchHintText: EntryFieldTypes.Text;
  speciesList: IContentfulEntryArrayField<ISpeciesFields>;
}

export type IHome = ExtractFields<IHomeFields>;

export interface ISideMenuFields {
  sideMenuItems: IContentfulEntryArrayField<ISideMenuItemFields>;
  socialMediaItems: IContentfulEntryArrayField<ISocialMediaItem>;
}

export type ISideMenu = ExtractFields<ISideMenuFields>;

export interface ISideMenuItemFields {
  title: EntryFieldTypes.Text;
  externalLink: EntryFieldTypes.Text;
  icon: EntryFieldTypes.AssetLink;
  internalLink: EntryFieldTypes.Text;
  internalReference: IContentfulEntryLinkField<IReferenceFields>;
}

export type ISideMenuItem = ExtractFields<ISideMenuItemFields>;

export interface ISocialMediaItem {
  socialMediaType: EntryFieldTypes.Text<
    'Facebook' | 'Twitter' | 'Youtube' | 'Instagram'
  >;
  socialMediaLink: EntryFieldTypes.Text;
}

export interface IAppSectionFields {
  label: EntryFieldTypes.Text;
  icon: EntryFieldTypes.AssetLink;
  internalLink: EntryFieldTypes.Text;
}

export type IAppSection = ExtractFields<IAppSectionFields>;

export interface IAppInfoFields {
  appVersion: EntryFieldTypes.Text;
  versionAvailableText: EntryFieldTypes.Text;
  boatrampLayer: EntryFieldTypes.AssetLink;
  sipsLayer: EntryFieldTypes.AssetLink;
}

export type IAppInfo = ExtractFields<IAppInfoFields>;

export interface IWatermarkFields {
  name: EntryFieldTypes.Text;
  speciesDetailsToggleField: EntryFieldTypes.Text;
  image: EntryFieldTypes.AssetLink;
  subtext?: EntryFieldTypes.Text;
  ruleOrDetail?: EntryFieldTypes.Text;
  scrollToCardOnClick: EntryFieldTypes.Boolean;
  showIconOnCard: EntryFieldTypes.Boolean;
}

export type IWatermark = ExtractFields<IWatermarkFields>;

export interface ISpeciesSummaryFields {
  speciesGroups: IContentfulEntryArrayField<ISpeciesGroupFields>;
  noTakePhoto: EntryFieldTypes.AssetLink;
  noTakePhotoSubtext: EntryFieldTypes.Text;
  watermarks: IContentfulEntryArrayField<IWatermarkFields>;
}

export type ISpeciesSummary = ExtractFields<ISpeciesSummaryFields>;

export interface ISpeciesGroupFields {
  speciesGroup: EntryFieldTypes.Text;
  speciesList: IContentfulEntryArrayField<ISpeciesFields>;
}

export type ISpeciesGroup = ExtractFields<ISpeciesGroupFields>;

export interface ISpeciesFields {
  caabCode: EntryFieldTypes.Integer;
  familyName: EntryFieldTypes.Text;
  commonName: EntryFieldTypes.Text;
  speciesPhotos: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  otherNames: EntryFieldTypes.RichText;
  scientificName: EntryFieldTypes.RichText;
  sizeRange: EntryFieldTypes.Text;
  sizeLimitsOnTakes: EntryFieldTypes.RichText;
  possessionLimitsOnTakes: EntryFieldTypes.RichText;
  possessionLimitsOnTakesTooltip: EntryFieldTypes.RichText;
  closures: EntryFieldTypes.RichText;
  frequentMisIdentification: EntryFieldTypes.RichText;
  description: EntryFieldTypes.RichText;
  distribution: EntryFieldTypes.RichText;
  freshwater: EntryFieldTypes.Boolean;
  tidal: EntryFieldTypes.Boolean;
  temporaryRestriction: EntryFieldTypes.Boolean;
  isPest: EntryFieldTypes.Boolean;
  ageLengthData?: EntryFieldTypes.AssetLink;
  stocks: EntryFieldTypes.RichText;
  detailsLink?: EntryFieldTypes.Text;
  detailsLinkTitle?: EntryFieldTypes.Text;
  detailsLinkText?: EntryFieldTypes.Text;
  speciesKeptLabel?: EntryFieldTypes.Text;
  speciesReleasedLabel?: EntryFieldTypes.Text;
}

export type ISpeciesEntry = IContentfulEntry<ISpeciesFields>;
export type ISpecies = ExtractFields<ISpeciesFields>;

export interface ISpeciesRulesContentFields {
  rulesContained: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  displayName: EntryFieldTypes.Object<ISpeciesDisplayName>;
}

export type ISpeciesRulesContent = ExtractFields<ISpeciesRulesContentFields>;

export interface ISpeciesDetailsContentFields {
  detailsContained: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  displayName: EntryFieldTypes.Object<ISpeciesDisplayName>;
}

interface ISpeciesDisplayName extends JsonObject {
  [key: string]: string;
}

export interface IContactFisheriesFields {
  contactFisheriesItems: IContentfulEntryArrayField<IContactFisheriesItemFields>;
}

export type IContactFisheries = ExtractFields<IContactFisheriesFields>;

export interface IContactFisheriesItemFields {
  label: EntryFieldTypes.Text;
  displayText: EntryFieldTypes.RichText;
  url: EntryFieldTypes.Text;
  buttonLabel: EntryFieldTypes.Text;
  secondDisplayText: EntryFieldTypes.RichText;
  secondUrl: EntryFieldTypes.Text;
  secondButtonLabel: EntryFieldTypes.Text;
}

export type IContactFisheriesItem = ExtractFields<IContactFisheriesItemFields>;

export interface IReferenceFields {
  sections: IContentfulEntryArrayField<ISectionFields>;
  placeholder: EntryFieldTypes.Text;
  title: EntryFieldTypes.Text;
}

export type IReference = ExtractFields<IReferenceFields>;

export interface ISectionFields {
  title: EntryFieldTypes.Text;
  icon: EntryFieldTypes.AssetLink;
  links: IContentfulEntryArrayField<ISectionLinkTypesFields>;
}

export type ISectionLinkTypesFields =
  | IExternalLinkFields
  | IContentFields
  | ISectionFields
  | IMapContentFields;
export type ISectionEntry = IContentfulEntry<ISectionFields>;
export type ISection = ExtractFields<ISectionFields>;

export interface IExternalLinkFields {
  title: EntryFieldTypes.Text;
  externalLink: EntryFieldTypes.Text;
  icon: EntryFieldTypes.AssetLink;
}

export type IExternalLinkEntry = IContentfulEntry<IExternalLinkFields>;
export type IExternalLink = ExtractFields<IExternalLinkFields>;

export interface IContentFields {
  title: EntryFieldTypes.Text;
  content: EntryFieldTypes.RichText;
  icon: EntryFieldTypes.AssetLink;
}

export type IContentEntry = IContentfulEntry<IContentFields>;
export type IContent = ExtractFields<IContentFields>;

export interface IMapContentFields {
  title: EntryFieldTypes.Text;
  featureLayers: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  queryFeatureLayers: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  icon: EntryFieldTypes.AssetLink;
  baseLayers: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  attributes: EntryFieldTypes.Object<IAttributes>;
}

export type IMapContentEntry = IContentfulEntry<IMapContentFields>;
export type IMapContent = ExtractFields<IMapContentFields>;

export interface IAttributes extends JsonObject {
  [key: string]: Array<IAttribute>;
}

export interface IAttribute extends JsonObject {
  attributeName: string;
  displayName: string;
}

export interface IReportShareTemplateFields {
  name: EntryFieldTypes.Text;
  templateImage: EntryFieldTypes.AssetLink;
  fields: EntryFieldTypes.Object<IReportShareField[]>;
}

export type IReportShareTemplateEntry =
  IContentfulEntry<IReportShareTemplateFields>;
export type IReportShareTemplate = ExtractFields<IReportShareTemplateFields>;

export interface IReportingMetadataFields {
  name: EntryFieldTypes.Text;
  retrieveMap: EntryFieldTypes.EntryLink<EntrySkeletonType<IMapContentFields>>;
  fishingMap: EntryFieldTypes.EntryLink<EntrySkeletonType<IMapContentFields>>;
  fishingMethods: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  reportShareTemplates: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<EntrySkeletonType<IReportShareTemplateFields>>
  >;
  //
  tooltipDidYouFishFrom: EntryFieldTypes.RichText;
  tooltipNumFishers: EntryFieldTypes.RichText;
  tooltipSkipper: EntryFieldTypes.RichText;
  tooltipCatchType: EntryFieldTypes.RichText;
  tooltipHasFish: EntryFieldTypes.RichText;
  tooltipSpecies: EntryFieldTypes.RichText;
  tooltipDepredated: EntryFieldTypes.RichText;
  tooltipRetrieveDateTime: EntryFieldTypes.RichText;
  tooltipRetrieveLocation: EntryFieldTypes.RichText;
  tooltipFishingLocation: EntryFieldTypes.RichText;
  tooltipReportName: EntryFieldTypes.RichText;
  tooltipComments: EntryFieldTypes.RichText;
  tooltipLaunchDateTime: EntryFieldTypes.RichText;
  tooltipPhotos: EntryFieldTypes.RichText;
  tooltipFishingMethod: EntryFieldTypes.RichText;
  tooltipEnvironmentalData: EntryFieldTypes.RichText;
  //
  tooltipSpeciesKept: EntryFieldTypes.RichText;
  tooltipSpeciesReleased: EntryFieldTypes.RichText;
  tooltipSpeciesSelect: EntryFieldTypes.RichText;
  //
  statsTooltipCaughtRecords: EntryFieldTypes.RichText;
  statsTooltipWhen: EntryFieldTypes.RichText;
  statsTooltipWhere: EntryFieldTypes.RichText;
  statsTooltipDepredated: EntryFieldTypes.RichText;
}

export type IReportingMetadataEntry =
  IContentfulEntry<IReportingMetadataFields>;
export type IReportingMetadata = ExtractFields<IReportingMetadataFields>;

export const getAssetFile = (asset: Asset) => {
  const file = asset?.fields?.file;
  return file?.hasOwnProperty('en-US') ? file['en-US'] : file;
};

export const getId = (
  content: IContentfulEntry<unknown> | Asset | DeletedEntry | DeletedAsset
) => content?.sys.id;

export enum ContentfulSyncStatus {
  Initial = 'Initial',
  UpdateChecked = 'UpdateChecked',
  EntriesSynced = 'EntriesSynced',
  AssetsDownloaded = 'AssetsDownloaded',
  AssetsSynced = 'AssetsSynced',
  Failure = 'Failure',
}

export type ContentfulEntryCollection<T extends EntrySkeletonType> =
  EntryCollection<T> & Stringifiable;

interface Stringifiable {
  stringifySafe: () => string;
}

export type IContentfulEntry<T extends FieldsType> = Entry<
  EntrySkeletonType<T>,
  'WITHOUT_UNRESOLVABLE_LINKS'
>;
export type IContentfulAsset = Asset<'WITHOUT_UNRESOLVABLE_LINKS'>;

export type ExtractFields<T> = IContentfulEntry<T>['fields'];

type IContentfulEntryLinkField<T> = EntryFieldTypes.EntryLink<
  EntrySkeletonType<T>
>;
type IContentfulEntryArrayField<T> = EntryFieldTypes.Array<
  IContentfulEntryLinkField<T>
>;
