export const MESSAGES = {
  fishIDSubmitSuccessMessage:
    'Your form has been submitted to improve the fish species identification',
  authSuccess: 'Login successful',
  authFailure:
    'Failed to login. There was an issue connecting to network, please try again later',
  submitAuthReminderMessage:
    "To submit a fishing trip record, you need to <b>sign in</b> with your details, <b>sign up</b> if you haven't yet, or <b>do it later</b> after recording your trip.",
  historyAuthReminderMessage:
    'You need to sign in to see your fishing trip history',
  reportProgressSavedMessage:
    'Your unfinished fishing trip will be saved locally in your device. Please submit the trip before you can record a new one.',
  loginPageContent:
    'To continue, select <b>Sign In</b> if you already have a Qld Fishing 2.0 app account, or <b>Sign up</b> if you don’t have one.',
  loginDisclaimer:
    'These will open the Queensland Government web page on your browser',
  contentfulNewContentMessage: `There have been updates since you last used the app. Do you want to download these updates now?<br><br><b>Warning:</b> Only tap ‘Yes’ if you have good internet connection. If not, tap ‘Cancel’ – you can keep using the app now and download the updates later.`,
  transactionTimeoutWarning:
    'Your submission is taking longer than usual, please check that you have reliable service. <b>Only if you have full service</b> log out of the app then log back in. \nIf your transaction remains pending, call 13 25 23. DO NOT uninstall/delete the app as all pending submissions and preferences will be lost.',
  progressSavedToastMessage: 'Your progress has been saved',
  deleteAccountFailMessage:
    'Page was closed before account was deleted. Please try again',
  deleteAccountSuccessMessage:
    'Account deletion request submitted successfully',
  submissionStatus:
    'Some of the submissions can happen even if you are not connected to the internet and sync later. <br/><br/>No further action is required',
  noReportsFoundForPeriod:
    'Sorry, you do not have any fishing trips recorded for this period',
  clearReportButtonText: 'Clear Trip',
  possessionLimitsDefaultTooltip:
    'Possession limit is per person unless otherwise specified.',
  depredationHelperText:
    'Do you suspect any of your fish were wholly or partially taken by any predatory species including sharks?',
  reportingGetStartedMessage: 'Submit a trip to get started.',
  emptyReportListMessage: 'No fishing trips to view.',
  emptyReportStatsMessage: 'No fishing statistics to view yet.',
};
