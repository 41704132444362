import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';

export const hapticWarning = () =>
  Capacitor.isNativePlatform()
    ? Haptics.notification({ type: NotificationType.Warning })
    : '';

export const hapticSuccess = () =>
  Capacitor.isNativePlatform()
    ? Haptics.notification({ type: NotificationType.Success })
    : '';

export const hapticError = () =>
  Capacitor.isNativePlatform()
    ? Haptics.notification({ type: NotificationType.Error })
    : '';

export const hapticLight = () =>
  Capacitor.isNativePlatform()
    ? Haptics.impact({ style: ImpactStyle.Light })
    : '';

if (Capacitor.isNativePlatform()) {
  Haptics.selectionStart();
}

export const hapticSelect = () =>
  Capacitor.isNativePlatform() ? Haptics.selectionChanged() : '';
