import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor() {}

  private async showWebUrl(url: URL, redirectUrl: string) {
    return new Promise<URLSearchParams>((resolve, reject) => {
      const popup = window.open(url);

      const timer = window.setInterval(() => {
        if (!popup || popup.closed) {
          window.clearInterval(timer);
          reject();
        }
        try {
          if (!popup) {
            return;
          }
          const popupLocation = popup.location?.href;
          if (encodeURI(popupLocation).includes(encodeURI(redirectUrl))) {
            window.clearInterval(timer);
            popup.close();
            resolve(new URL(popupLocation).searchParams);
          }
        } catch (e) {
          if (e instanceof DOMException) {
            return;
          }
        }
      }, 1);
    });
  }

  private delay(ms: number = 200) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async showNativeUrl(url: URL, redirectUrl: string) {
    return new Promise<URLSearchParams>(async (resolve, reject) => {
      let closed = false;

      const browserListener = await Browser.addListener(
        'browserFinished',
        async () => {
          await this.delay();
          await removeListeners();

          if (!closed) {
            reject();
          }
        }
      );

      const appListener = await App.addListener('appUrlOpen', async (app) => {
        closed = true;
        await removeListeners();
        try {
          await Browser.close();
        } catch (e) {
        } finally {
          resolve(new URL(app.url).searchParams);
        }
      });

      const removeListeners = () =>
        Promise.allSettled([appListener.remove(), browserListener.remove()]);

      await Browser.open({
        url: url.href,
      });
    });
  }

  public async showUrl(
    urlToGo: string,
    redirectUrl: string
  ): Promise<URLSearchParams> {
    const url = new URL(urlToGo);
    url.searchParams.set('redirectUrl', redirectUrl);

    if (Capacitor.isNativePlatform()) {
      return this.showNativeUrl(url, redirectUrl);
    } else {
      return this.showWebUrl(url, redirectUrl);
    }
  }
}
