import { Location } from '@qld-recreational/rec-api';

export enum ReportDiaryGraphRange {
  W,
  M,
  // '6M',
  Q,
  Y,
}

export function getRangeTimeBuffer(range: ReportDiaryGraphRange): {
  count: number;
  unit: moment.unitOfTime.Base;
} {
  switch (range) {
    case ReportDiaryGraphRange.W:
      return { count: 0.5, unit: 'week' };
    case ReportDiaryGraphRange.M:
      return { count: 15, unit: 'day' };
    case ReportDiaryGraphRange.Q:
      return { count: 2, unit: 'month' };
    case ReportDiaryGraphRange.Y:
      return { count: 0.5, unit: 'year' };
  }
}

export function getRangeTimeUnit(
  range: ReportDiaryGraphRange
): moment.unitOfTime.Base {
  switch (range) {
    case ReportDiaryGraphRange.W:
      return 'day';
    case ReportDiaryGraphRange.M:
      return 'day';
    case ReportDiaryGraphRange.Q:
      return 'month';
    case ReportDiaryGraphRange.Y:
      return 'month';
  }
}

export interface IDiaryFishingEvent {
  speciesId: string;
  kept: number;
  released: number;
  returnLocation: Location;
  fishingLocations: Location[];
  date: Date;
}

export interface IReportDiarySpecies {
  speciesId: string;
  name: string;
  kept: number;
  released: number;
}

export interface IReportDiaryTotal {
  kept: number;
  released: number;
}
