import {
  createFeature,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { BackgroundRequestActions } from './background-request.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { BackgroundRequest } from './background-request.model';
import { ViewStatus } from '../shared/ViewStatus';
import { complement, equals, isNil } from '@qld-recreational/ramda';
import { deleteAccountSuccess, switchUser } from '../auth/auth.actions';
import { v4 as uuidv4 } from 'uuid';
import { utcNow } from '@qld-recreational/moment';
import { clearTestData, switchEnv } from '../settings/settings.actions';

export const backgroundRequestFeatureKey = 'backgroundRequests';

const adapter = createEntityAdapter<BackgroundRequest>({
  selectId: (backgroundRequest) => backgroundRequest.id,
});

const initialState = adapter.getInitialState({
  viewStatus: ViewStatus.Initial,
});

export type IBackgroundRequestState = typeof initialState;

export const backgroundRequestPersistenceKeys = Object.keys(
  initialState
).filter(complement(equals('viewStatus')));

const reducer = createReducer(
  initialState,
  on(
    BackgroundRequestActions.addBackgroundRequest,
    (state, { backgroundRequest }) =>
      adapter.upsertOne(
        {
          ...backgroundRequest,
          ...(isNil(backgroundRequest.id) ? { id: uuidv4() } : {}),
          status: ViewStatus.Loading,
          dateCreated: utcNow(),
        },
        state
      )
  ),
  on(BackgroundRequestActions.deleteBackgroundRequest, (state, action) =>
    adapter.removeOne(action.id, {
      ...state,
      viewStatus: ViewStatus.Success,
    })
  ),
  on(BackgroundRequestActions.submitBackgroundRequestFailure, (state, action) =>
    adapter.updateOne(action.backgroundRequest, {
      ...state,
      viewStatus: ViewStatus.Failure,
    })
  ),
  on(
    BackgroundRequestActions.attemptBackgroundRequest,
    BackgroundRequestActions.submitBackgroundRequest,
    (state) => ({
      ...state,
      viewStatus: ViewStatus.Loading,
    })
  ),
  on(BackgroundRequestActions.noConnectionBackgroundRequest, (state) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
  })),
  on(
    switchUser,
    switchEnv,
    clearTestData,
    deleteAccountSuccess,
    () => initialState
  )
);

const selectors = adapter.getSelectors(
  createFeatureSelector(backgroundRequestFeatureKey)
);

const backgroundRequestFeature = createFeature({
  name: backgroundRequestFeatureKey,
  reducer,
  extraSelectors: (baseSelectors) => ({
    selectBackgroundRequests: selectors.selectAll,
    selectIsLoading: createSelector(
      baseSelectors.selectViewStatus,
      equals(ViewStatus.Loading)
    ),
    selectNumBackgroundRequests: selectors.selectTotal,
  }),
});

export const {
  reducer: backgroundRequestReducer,
  selectViewStatus,
  selectIsLoading,
  selectBackgroundRequests,
  selectNumBackgroundRequests,
} = backgroundRequestFeature;
