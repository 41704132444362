import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const search = new URLSearchParams({ redirectUrl: state.url });

  return authService.isLoggedIn$.pipe(
    map(
      (isLoggedIn) =>
        isLoggedIn || router.parseUrl('login' + '?' + search.toString())
    )
  );
};
