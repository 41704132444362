import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { LogoPipe } from './pipe/logo.pipe';
import { NthPipe } from './pipe/nth.pipe';
import { InstructionService } from './service/instruction.service';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { DefaultPipe } from './pipe/default.pipe';

@NgModule({
  declarations: [SafeUrlPipe, LogoPipe, NthPipe, DefaultPipe],
  exports: [SafeUrlPipe, LogoPipe, NthPipe, DefaultPipe],
  imports: [CommonModule],
  providers: [InstructionService, OpenNativeSettings],
})
export class SharedModule {}
