export enum IPreferenceLocationType {
  BoatRamp = 'boat ramp',
  SIP = 'SIP',
}

export const locationPreferenceLayerName = {
  [IPreferenceLocationType.BoatRamp]: 'Boat ramps',
  [IPreferenceLocationType.SIP]: 'SIPS Dams Point',
};

export const locationPreferenceTypeName = {
  'Boat ramps': IPreferenceLocationType.BoatRamp,
  'SIPS Dams Point': IPreferenceLocationType.SIP,
};
