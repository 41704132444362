import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { hapticWarning } from '@qld-recreational/haptic';
import { networkConnected } from '@qld-recreational/network';
import { PendingTransactionComponent } from '../pending-transaction/pending-transaction.component';
import { equals, isNil } from '@qld-recreational/ramda';
import { MESSAGES } from '../messages';
import { ToastService } from '@qld-recreational/toast';
import { environment } from '../../environments/environment';
import { Observable, UnaryFunction, from, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PendingTransactionService {
  private readonly PENDING_TRANSACTION_MODAL_ID = 'pending-transaction-modal';
  private timeoudId?: ReturnType<typeof setTimeout>;
  private readonly TIMEOUT_DURATION = 1000 * (environment.production ? 90 : 5);

  private readonly ENABLE_TIMEOUT = false;

  constructor(
    private modalController: ModalController,
    private toastController: ToastService
  ) {}

  private async presentModal() {
    const isNetworkConnected = await networkConnected();
    if (!isNetworkConnected) {
      return;
    }
    hapticWarning();

    const modal = await this.modalController.create({
      component: PendingTransactionComponent,
      id: this.PENDING_TRANSACTION_MODAL_ID,
      cssClass: this.PENDING_TRANSACTION_MODAL_ID,
      animated: false,
    });

    await modal.present();

    this.clearTimeout();

    if (this.ENABLE_TIMEOUT) {
      this.timeoudId = setTimeout(
        () => this.handleTimeout(),
        this.TIMEOUT_DURATION
      );
    }

    return;
  }

  private clearTimeout(): void {
    if (!isNil(this.timeoudId)) {
      clearTimeout(this.timeoudId);
      this.timeoudId = undefined;
    }
  }

  private async handleTimeout(): Promise<void> {
    this.toastController.presentWarningToast(
      MESSAGES.transactionTimeoutWarning
    );
    await this.dismissPendingTransactionModal();
  }

  public checkAndPresentPendingTransactionModal<T>(): UnaryFunction<
    Observable<T>,
    Observable<T>
  > {
    return switchMap((payload) =>
      from(this.presentModal()).pipe(map(() => payload))
    );
  }

  public async dismissPendingTransactionModal() {
    this.clearTimeout();

    const modal = await this.modalController.getTop();
    if (isNil(modal)) {
      return;
    }
    if (!equals(modal.id, this.PENDING_TRANSACTION_MODAL_ID)) {
      return;
    }
    await this.modalController.dismiss(
      null,
      null,
      this.PENDING_TRANSACTION_MODAL_ID
    );
  }
}
