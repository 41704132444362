import { createFeature, createReducer, on } from '@ngrx/store';
import { ReportsActions } from './reports.actions';
import { IReportValues } from '../shared/model';
import { clearTestData, switchEnv } from '../settings/settings.actions';
import { deleteAccountSuccess } from '../auth/auth.actions';

export interface IReportsState {
  reportProgress: IReportValues | null;
}

const initialState: IReportsState = {
  reportProgress: null,
};

export const reportsFeature = createFeature({
  name: 'reports',
  reducer: createReducer(
    initialState,
    on(ReportsActions.saveReportProgress, (state, action) => ({
      ...state,
      reportProgress: action.reportProgress,
    })),
    on(ReportsActions.clearReportProgress, (state) => ({
      ...state,
      reportProgress: null,
    })),
    on(switchEnv, clearTestData, deleteAccountSuccess, () => initialState)
  ),
});

export const {
  name: reportsFeatureKey,
  reducer,
  selectReportProgress,
} = reportsFeature;
