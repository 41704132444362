<ion-menu contentId="main" [swipeGesture]="false">
  <!--  <ion-header>-->
  <!--    <ion-toolbar>-->
  <!--      <ion-title>Menu</ion-title>-->
  <!--    </ion-toolbar>-->
  <!--  </ion-header>-->
  <ion-content [forceOverscroll]="false">
    <ion-list>
      <qld-recreational-side-menu-item
        *ngFor="let item of sideMenu?.sideMenuItems"
        [item]="item.fields"
        (click)="openLink(item.fields)"
      ></qld-recreational-side-menu-item>
      <ion-item
        lines="none"
        *ngIf="isDebugMode$ | async"
        (click)="goToSettings()"
      >
        <ion-thumbnail>
          <ion-icon name="cog-outline"></ion-icon>
        </ion-thumbnail>
        <ion-label> Settings </ion-label>
      </ion-item>
      <ion-item lines="none" *ngIf="email$ | async" (click)="goToAccount()">
        <ion-thumbnail>
          <ion-icon name="person-outline"></ion-icon>
        </ion-thumbnail>
        <ion-label
          ><h2>Account</h2>
          <p>Signed in as {{ email$ | async }}</p></ion-label
        >
      </ion-item>
      <ion-item lines="none" *ngIf="!(email$ | async)" (click)="login()">
        <ion-thumbnail>
          <ion-icon name="log-in-outline"></ion-icon>
        </ion-thumbnail>
        <ion-label> Sign in / Sign up</ion-label>
      </ion-item>
    </ion-list>
    <ion-row class="social-media">
      <ion-col size="3" *ngFor="let item of sideMenu?.socialMediaItems">
        <ion-button
          fill="clear"
          (click)="openExternalLink(item.fields.socialMediaLink)"
        >
          <ion-icon
            slot="icon-only"
            [name]="item.fields.socialMediaType | logo"
          ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <qld-recreational-app-version></qld-recreational-app-version>
  </ion-content>
</ion-menu>
