import { createAction, props } from '@ngrx/store';

export const loadFavourites = createAction(
  '[Species] Load Favourites',
  props<{ caabCodes: number[] }>()
);

export const toggleFavourite = createAction(
  '[Species] Toggle Favourite',
  props<{ caabCode: number }>()
);
