import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VersionMismatchGuard } from './version-mismatch/version-mismatch.guard';
import { authGuard } from './shared/guard/auth.guard';
import { reportExistsGuard } from './shared/guard/report-exists.guard';

const routes: Routes = [
  {
    path: 'disclaimer',
    loadChildren: () =>
      import('./disclaimer/disclaimer.module').then((m) => m.DisclaimerModule),
  },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingModule
      ),
  },
  {
    path: 'sync',
    loadChildren: () => import('./sync/sync.module').then((m) => m.SyncModule),
  },
  {
    path: 'version-mismatch',
    loadChildren: () =>
      import('./version-mismatch-page/version-mismatch-page.module').then(
        (m) => m.VersionMismatchPageModule
      ),
  },
  {
    path: 'home',
    canActivate: [VersionMismatchGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login-page/login-page.component').then(
        (m) => m.LoginPageComponent
      ),
  },
  {
    path: 'account',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./account-page/account-page.component').then(
        (m) => m.AccountPageComponent
      ),
  },
  {
    path: 'species',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./species-list/species-list.module').then(
        (m) => m.SpeciesListModule
      ),
  },
  {
    path: 'about-my-fish',
    canActivate: [VersionMismatchGuard, authGuard],
    loadChildren: () =>
      import('./about-my-fish/about-my-fish.module').then(
        (m) => m.AboutMyFishModule
      ),
  },
  {
    path: 'preferences-home',
    canActivate: [VersionMismatchGuard, authGuard],
    loadComponent: () =>
      import('./preferences-home/preferences-home.component').then(
        (m) => m.PreferencesHomeComponent
      ),
  },
  {
    path: 'report-home',
    canActivate: [VersionMismatchGuard],
    loadComponent: () =>
      import('./report-home-page/report-home-page.component').then(
        (m) => m.ReportHomeComponent
      ),
  },
  {
    path: 'report',
    canActivate: [VersionMismatchGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./report-page/report-page.component').then(
            (m) => m.ReportPageComponent
          ),
      },
      {
        path: ':clientRequestID',
        canActivate: [authGuard, reportExistsGuard],
        loadComponent: () =>
          import('./report-page/report-page.component').then(
            (m) => m.ReportPageComponent
          ),
      },
    ],
  },
  {
    path: 'additional-details',
    canActivate: [VersionMismatchGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './report-additional-details-page/report-additional-details-page.component'
          ).then((m) => m.ReportAdditionalDetailsPageComponent),
      },
      {
        path: ':clientRequestID',
        canActivate: [authGuard, reportExistsGuard],
        loadComponent: () =>
          import(
            './report-additional-details-page/report-additional-details-page.component'
          ).then((m) => m.ReportAdditionalDetailsPageComponent),
      },
    ],
  },
  {
    path: 'report-diary',
    canActivate: [VersionMismatchGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./report-diary-page/report-diary-page.component').then(
            (m) => m.ReportDiaryPageComponent
          ),
      },
      {
        path: 'species',
        children: [
          {
            path: '',
            redirectTo: '/report-diary',
            pathMatch: 'full',
          },
          {
            path: ':speciesId',
            loadComponent: () =>
              import('./species-diary-page/species-diary-page.component').then(
                (m) => m.SpeciesDiaryPageComponent
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'report-history',
    canActivate: [VersionMismatchGuard],
    loadComponent: () =>
      import('./report-history-page/report-history-page.component').then(
        (m) => m.ReportHistoryPageComponent
      ),
  },
  {
    path: 'which-fish-is-this',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./which-fish-is-this-page/which-fish-is-this.module').then(
        (m) => m.WhichFishIsThisModule
      ),
  },
  {
    path: 'species-detail/:id',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./species-detail/species-detail.module').then(
        (m) => m.SpeciesDetailModule
      ),
  },
  {
    path: 'contact-fisheries',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./contact-fisheries/contact-fisheries.module').then(
        (m) => m.ContactFisheriesModule
      ),
  },
  {
    path: 'contact-fisheries-form',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./contact-fisheries-form/contact-fisheries-form.module').then(
        (m) => m.ContactFisheriesFormModule
      ),
  },
  {
    path: 'reference',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./reference/reference.module').then((m) => m.ReferenceModule),
  },
  {
    path: 'can-i-fish-here',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./can-i-fish-here/can-i-fish-here.module').then(
        (m) => m.CanIFishHereModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings-page/settings-page.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'notifications',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./notifications-page/notifications-page.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'search',
    canActivate: [VersionMismatchGuard],
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'preferences',
    canActivate: [VersionMismatchGuard, authGuard],
    children: [
      {
        path: 'boat-ramps',
        loadComponent: () =>
          import(
            './preferences-boat-ramps/preferences-boat-ramps.component'
          ).then((m) => m.PreferencesBoatRampsComponent),
      },
      {
        path: 'sips',
        loadComponent: () =>
          import('./preferences-sips/preferences-sips.component').then(
            (m) => m.PreferencesSipsComponent
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
