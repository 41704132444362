import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of, switchMap, timer } from 'rxjs';
import { ApiService } from '../shared/api.service';
import { authSuccess } from '../auth/auth.actions';
import { Store } from '@ngrx/store';
import { equals, isNilOrEmpty, not } from '@qld-recreational/ramda';
import { PreferencesActions } from './preferences.actions';
import { loadFavourites, toggleFavourite } from '../species/species.actions';
import {
  selectPreferencesHasChange,
  selectPreferencesViewStatus,
} from './preferences.reducer';
import { ViewStatus } from '../shared/ViewStatus';
import { PreferencesService } from './preferences.service';
import { IPreferenceLocationType } from '../shared/preference-location/preference-location.model';
import { loadEnvs } from '../settings/settings.actions';
import { selectUserID } from '../auth/auth.reducer';

@Injectable()
export class PreferencesEffects {
  private readonly START_DELAY = 500;
  private readonly SUBMIT_INTERVAL = 10 * 1_000;

  public loadPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSuccess, loadEnvs),
      withLatestFrom(this.store.select(selectUserID)),
      filter(([, email]) => not(isNilOrEmpty(email))),
      delay(this.START_DELAY),
      switchMap(() =>
        this.apiService.getUserPreferences().pipe(
          map((payload) =>
            this.preferencesService.fromPayload(payload.settings)
          ),
          concatMap((preferences) => {
            // if the preferences are empty, meaning the user never set preferences before
            // set the preferences with the current preference from their device
            if (isNilOrEmpty(preferences)) {
              return of(PreferencesActions.preferencesChanged());
            }

            return [
              PreferencesActions.loadLocations({
                ids: preferences.boatramps,
                preferenceType: IPreferenceLocationType.BoatRamp,
              }),
              PreferencesActions.loadLocations({
                ids: preferences.sips,
                preferenceType: IPreferenceLocationType.SIP,
              }),
              loadFavourites({ caabCodes: preferences.species }),
            ];
          })
        )
      )
    )
  );

  public preferenceChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PreferencesActions.toggleLocation, // Location preferences
        PreferencesActions.deleteLocation,
        toggleFavourite // Species preferences
      ),
      debounceTime(2000),
      map(() => PreferencesActions.preferencesChanged())
    )
  );

  public listenForChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnvs),
      switchMap(() =>
        timer(this.START_DELAY * 2, this.SUBMIT_INTERVAL).pipe(
          withLatestFrom(this.store.select(selectUserID)),
          filter(([, userId]) => not(isNilOrEmpty(userId))),
          withLatestFrom(this.store.select(selectPreferencesViewStatus)),
          filter(([, viewStatus]) => !equals(viewStatus, ViewStatus.Loading)),
          withLatestFrom(this.store.select(selectPreferencesHasChange)),
          filter(([, hasChange]) => hasChange),
          map(() => PreferencesActions.attemptSavePreferences())
        )
      )
    )
  );

  public submitPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.attemptSavePreferences),
      withLatestFrom(
        this.preferencesService
          .getCurrentPreferences()
          .pipe(map((pref) => this.preferencesService.toPayload(pref)))
      ),
      switchMap(([, payload]) =>
        this.apiService.saveUserPreferences(payload).pipe(
          map(() => PreferencesActions.savePreferencesSuccess()),
          catchError((error) => {
            console.error(error);
            return of(PreferencesActions.savePreferencesFailure());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private preferencesService: PreferencesService,
    private store: Store
  ) {}
}
