import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IReportValues } from '../shared/model';

export const ReportsActions = createActionGroup({
  source: 'Reports',
  events: {
    'Save Report Progress': props<{
      reportProgress: IReportValues;
      toastMessage?: string;
    }>(),
    'Clear Report Progress': emptyProps(),
  },
});
