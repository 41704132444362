import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reportListFeatureKey, reportListReducer } from './report-list.reducer';
import { ReportListEffects } from './report-list.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ReportListEffects]),
    StoreModule.forFeature(reportListFeatureKey, reportListReducer),
  ],
})
export class ReportListModule {}
