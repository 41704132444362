import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ReportDiaryActions } from './report-diary.actions';
import {
  DEFAULT_TIME_FILTER,
  ReportDiaryCatchTypes,
} from '../shared/filter.model';
import { prop } from '@qld-recreational/ramda';

export const reportDiaryFeatureKey = 'reportDiary';

const initialState = {
  filters: {
    timeFilter: DEFAULT_TIME_FILTER,
    catchTypeFilter: ReportDiaryCatchTypes.Boat,
  },
};

export type IReportDiaryState = typeof initialState;

const reducer = createReducer(
  initialState,
  on(ReportDiaryActions.init, () => initialState),
  on(ReportDiaryActions.changeTimeFilter, (state, { timeFilter }) => ({
    ...state,
    filters: {
      ...state.filters,
      timeFilter,
    },
  })),
  on(
    ReportDiaryActions.changeCatchTypeFilter,
    (state, { catchTypeFilter }) => ({
      ...state,
      filters: {
        ...state.filters,
        catchTypeFilter,
      },
    })
  )
);

const reportDiaryFeature = createFeature({
  name: reportDiaryFeatureKey,
  reducer,
  extraSelectors: (baseSelectors) => ({
    selectReportDiaryTimeFilter: createSelector(
      baseSelectors.selectFilters,
      prop('timeFilter')
    ),
    selectReportDiaryCatchTypeFilter: createSelector(
      baseSelectors.selectFilters,
      prop('catchTypeFilter')
    ),
  }),
});

export const {
  reducer: reportDiaryReducer,
  selectReportDiaryTimeFilter,
  selectReportDiaryCatchTypeFilter,
} = reportDiaryFeature;
