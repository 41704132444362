import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'qld-recreational-pending-transaction',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './pending-transaction.component.html',
  styleUrls: ['./pending-transaction.component.scss'],
})
export class PendingTransactionComponent {}
