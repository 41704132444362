import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  checkUpdateFailure,
  retry,
  syncAssets,
  syncAssetsFailure,
  syncAssetsSuccess,
  syncEntries,
  syncEntriesFailure,
  syncEntriesSuccess,
  syncSkipped,
} from './contentful.actions';
import {
  catchError,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IContentfulState } from './contentful.reducer';
import { ContentfulService } from './contentful.service';
import { from, of } from 'rxjs';
import {
  loadEnvs,
  switchContentfulContext,
} from '../settings/settings.actions';
import { IAppState, selectNextSyncToken } from '../app-state/app-state.reducer';
import { isNil } from 'rambda';
import { VersionMismatchService } from '../version-mismatch/version-mismatch.service';

@Injectable()
export class ContentfulEffects {
  public checkUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnvs, retry),
      tap(() => this.contentfulService.preventScreenSleep()),
      switchMap(() => this.contentfulService.checkRemoteUpdate()),
      withLatestFrom(
        this.appStateStore.select(selectNextSyncToken).pipe(map(isNil))
      ),
      switchMap(([syncCollection, firstLaunch]) =>
        from(this.versionMismatchService.getAppInfo()).pipe(
          map((entry) => entry?.fields.appVersion ?? '0.0.0'),
          map((version) => this.versionMismatchService.clientIsNewer(version)),
          switchMap((clientIsNewer) =>
            from(
              this.contentfulService.presentNotificationIfHasChanges(
                syncCollection,
                firstLaunch || clientIsNewer
              )
            ).pipe(
              map(() => syncEntries({ syncCollection })),
              catchError(() =>
                of(
                  firstLaunch
                    ? checkUpdateFailure({ error: 'Sync Entries Error' })
                    : syncSkipped()
                )
              )
            )
          )
        )
      ),
      catchError((err) =>
        of(checkUpdateFailure({ error: err?.message || 'Sync Entries Error' }))
      )
    )
  );

  public changeStage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchContentfulContext),
      switchMap(() => this.contentfulService.clearCache()),
      mapTo(retry())
    )
  );

  public syncEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncEntries),
      switchMap(({ syncCollection }) =>
        this.contentfulService.syncEntries(syncCollection)
      ),
      map((syncCollection) => syncEntriesSuccess({ syncCollection })),
      catchError((err) =>
        of(syncEntriesFailure({ error: err?.message || 'Sync Entries Error' }))
      )
    )
  );

  public syncEntriesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncEntriesSuccess),
      map((syncCollection) => syncAssets(syncCollection))
    )
  );

  public syncAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncAssets),
      switchMap(({ syncCollection }) =>
        this.contentfulService.syncAssets(syncCollection)
      ),
      map((nextSyncToken) => syncAssetsSuccess({ nextSyncToken })),
      catchError((err) =>
        of(syncAssetsFailure({ error: err?.message || 'Sync Assets Error' }))
      )
    )
  );

  public syncAssetsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(syncAssetsSuccess),
        tap(() => this.contentfulService.allowScreenSleep()),
        map(({ nextSyncToken }) =>
          this.contentfulService.updateNextToken(nextSyncToken)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private contentfulStore: Store<IContentfulState>,
    private appStateStore: Store<IAppState>,
    private contentfulService: ContentfulService,
    private versionMismatchService: VersionMismatchService
  ) {}
}
