import { pipe, difference, length } from '@qld-recreational/ramda';

export const unreadNotificationCount = (
  notificationIds: string[],
  readNotificationIds: string[]
) => {
  return pipe(
    difference(notificationIds),
    length
  )(readNotificationIds) as number;
};
