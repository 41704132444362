import { isNilOrEmpty } from '@qld-recreational/ramda';
import { qldTime, qldTimeNow } from './moment';
import * as moment from 'moment';

export interface DateTime {
  day?: string;
  month?: string;
  year?: string;
  hour?: string;
  min?: string;
}

export function toDate(date: string | Date): DateTime {
  const d = typeof date === 'string' ? new Date(date) : date;
  return {
    day: d.getDate().toString(),
    month: (d.getMonth() + 1).toString(),
    year: d.getFullYear().toString(),
  };
}

export function toDateTime(datetime: string | Date): DateTime {
  if (isNilOrEmpty(datetime)) {
    return null;
  }

  const date =
    typeof datetime === 'string' ? new Date(datetime.split('+')[0]) : datetime;
  return {
    day: date.getDate().toString(),
    month: (date.getMonth() + 1).toString(),
    year: date.getFullYear().toString(),
    hour: date.getHours().toString(),
    min: date.getMinutes().toString().padStart(2, '0'),
  };
}

export function fromDate(date: DateTime): string {
  if (dateIsNull(date)) {
    return null;
  }

  return `${date.year.padStart(4, '20')}-${date.month.padStart(
    2,
    '0'
  )}-${date.day.padStart(2, '0')}`;
}

export function fromDateTime(dateTime: DateTime): string {
  if (dateTimeIsNull(dateTime)) {
    return null;
  }

  const { day, month, year, hour, min } = dateTime;
  return `${year.padStart(4, '20')}-${month.padStart(2, '0')}-${day.padStart(
    2,
    '0'
  )}T${hour.padStart(2, '0')}:${min.padStart(2, '0')}:00`;
}

export function dateTimePartialEntered(dateTime: DateTime): boolean {
  if (isNilOrEmpty(dateTime)) {
    return false;
  }
  return (
    !isNilOrEmpty(dateTime.day) ||
    !isNilOrEmpty(dateTime.month) ||
    !isNilOrEmpty(dateTime.year) ||
    !isNilOrEmpty(dateTime.hour) ||
    !isNilOrEmpty(dateTime.min)
  );
}

export function dateIsNull(dateTime: DateTime): boolean {
  return (
    isNilOrEmpty(dateTime) ||
    isNilOrEmpty(dateTime.day) ||
    isNilOrEmpty(dateTime.month) ||
    isNilOrEmpty(dateTime.year)
  );
}

export function dateTimeIsNull(dateTime: DateTime): boolean {
  return (
    isNilOrEmpty(dateTime) ||
    isNilOrEmpty(dateTime.day) ||
    isNilOrEmpty(dateTime.month) ||
    isNilOrEmpty(dateTime.year) ||
    isNilOrEmpty(dateTime.hour) ||
    isNilOrEmpty(dateTime.min)
  );
}

export function initialQldDateTime(): DateTime {
  const qldNow = moment();

  qldNow.minute(15 * Math.floor(qldNow.minute() / 15));

  return toDate(qldNow.format());
}
