import { Storage } from '@ionic/storage-angular';
import { ActionReducer } from '@ngrx/store';
import {
  equals,
  isNil,
  mergeAll,
  not,
  omit,
  pick,
  startsWith,
} from '@qld-recreational/ramda';
import { loadEnvs } from '../settings/settings.actions';
import {
  updateAssetsCached,
  updateAssetsDownloaded,
  updateEntriesCached,
} from '../contentful/contentful.actions';

const STORAGE_KEY = 'IONIC_STORAGE_APP_STATE';

export const fetchState = async (storage: Storage): Promise<{}> => {
  const state = {};

  const keys = await storage.keys();

  await Promise.allSettled(
    keys.filter(startsWith(STORAGE_KEY)).map(async (key) => {
      state[key.replace(STORAGE_KEY + '-', '')] = await storage.get(key);
    })
  );

  // Merge with local storage state for the users who are coming from a version of the app that didn't have the
  // ionic storage implemented. For basically all cases, after the first boot, this will be an empty object
  const localStorageState = getSavedState();

  return {
    ...localStorageState,
    ...state,
  };
};

export const saveState = async (
  storage: Storage,
  state: any,
  keys: (string | { [key: string]: string[] })[]
): Promise<any> => {
  return Promise.allSettled(
    keys.map(async (key) => {
      if (typeof key === 'string') {
        return state[key] && storage.set(STORAGE_KEY + '-' + key, state[key]);
      }

      return Promise.allSettled(
        Object.entries(key).map(async ([innerKey, value]) => {
          const slice = state[innerKey];

          if (!slice) {
            return;
          }

          return storage.set(STORAGE_KEY + '-' + innerKey, pick(value, slice));
        })
      );
    })
  );
};

export const StorageSyncActions = {
  HYDRATED: 'NSIS_APP_HYDRATED',
};

export const NGRX_ACTIONS = {
  INIT: '@ngrx/store/init',
  UPDATE_REDUCERS: '@ngrx/store/update-reducers',
  RECOMPUTE: '@ngrx/store-devtools/recompute',
  EFFECTS_INIT: '@ngrx/effects/init',
};

export const NGRX_STORAGE_SYNC_IGNORE_ACTIONS = [
  StorageSyncActions.HYDRATED,
  NGRX_ACTIONS.INIT,
  NGRX_ACTIONS.EFFECTS_INIT,
  NGRX_ACTIONS.UPDATE_REDUCERS,
  NGRX_ACTIONS.RECOMPUTE,
  loadEnvs.type,
  updateAssetsDownloaded.type,
  updateEntriesCached.type,
  updateAssetsCached.type,
];

export interface StorageSyncOptions {
  hydratedStateKey?: string;
}

export const storageSync = (options?: StorageSyncOptions) => {
  const { hydratedStateKey } = Object.assign({}, options || {});

  let hydratedState;

  return (reducer: ActionReducer<any>) => (state: any, action: any) => {
    const { type, payload } = action;
    if (type === StorageSyncActions.HYDRATED) {
      state = Object.assign({}, state, payload);
      hydratedState = state;
    }

    const isHydrated = not(isNil(hydratedState));
    if (equals(type, NGRX_ACTIONS.UPDATE_REDUCERS) && isHydrated) {
      const mergedState = mergeAll([
        pick(action.features, hydratedState),
        omit(action.features, state),
      ]);

      const nextState = reducer(mergedState, action);

      return {
        ...mergedState,
        ...nextState,
      };
    }

    const nextState = Object.assign({}, reducer(state, action), {
      [hydratedStateKey]: isHydrated,
    });

    return nextState;
  };
};

export const LOCAL_STORAGE_PREFIX = 'qld-rec';

function getSavedState() {
  let savedState = {};

  const foundKeys: string[] = [];

  let index = 0;
  while (index < localStorage.length) {
    const key = localStorage.key(index);
    index++;

    if (!key.startsWith(LOCAL_STORAGE_PREFIX)) {
      continue;
    }
    const saved = localStorage.getItem(key);

    if (!saved) {
      continue;
    }

    foundKeys.push(key);
    const slice = JSON.parse(saved);
    const sliceName = key.replace(LOCAL_STORAGE_PREFIX + '-', '');

    savedState[sliceName] = slice;
  }

  foundKeys.forEach((key) => localStorage.removeItem(key));

  return savedState;
}
