import {
  INotificationState,
  notificationsFeatureKey,
  selectAll,
  selectIds,
} from './notification.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { unreadNotificationCount } from './unread-notification-count';

const selectNotificationState = createFeatureSelector<INotificationState>(
  notificationsFeatureKey
);

export const selectNotifications = createSelector(
  selectNotificationState,
  selectAll
);

export const selectNotificationIds = createSelector(
  selectNotificationState,
  selectIds
);

export const selectUnreadNotificationCount = createSelector(
  selectNotificationState,
  (state) => {
    const notificationIds = selectIds(state) as string[];
    const readNotificationIds = state.readNotificationIds;
    return unreadNotificationCount(notificationIds, readNotificationIds);
  }
);

export const selectNotificationsLastLoadedDateTime = createSelector(
  selectNotificationState,
  (state) => state.lastLoadedDateTime
);
