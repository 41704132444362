import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import {
  ISettingState,
  selectIsDebugMode,
} from '../../settings/settings.reducer';
import { enableDebugMode } from '../../settings/settings.actions';
import { Subscription } from 'rxjs';
import { hapticSelect, hapticWarning } from '@qld-recreational/haptic';
import { ToastService } from '@qld-recreational/toast';

@Component({
  selector: 'qld-recreational-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['app-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppVersionComponent implements OnInit, OnDestroy {
  public version: string;
  public buildNumber: string;
  public debugClickCount = 0;
  public maxDebugClickCount = 14;
  public debugEnabled = false;
  public halfWayMessageShown = false;
  public isDebugMode$$: Subscription;

  constructor(
    private toastService: ToastService,
    private settingsStore: Store<ISettingState>
  ) {}

  public clickVersion(): void {
    if (this.debugEnabled) {
      return;
    }
    this.debugClickCount++;
    if (
      this.debugClickCount > this.maxDebugClickCount / 2 &&
      !this.halfWayMessageShown
    ) {
      this.halfWayMessageShown = true;
      hapticSelect();
      this.toastService.presentDebugModeToast('You are almost there! Tap Tap!');
    }
    if (this.debugClickCount < this.maxDebugClickCount) {
      return;
    }
    hapticWarning();
    this.enableDebug();
  }

  public enableDebug(): void {
    this.toastService.presentDebugModeToast('Debug mode enabled!');
    this.settingsStore.dispatch(enableDebugMode());
    this.debugClickCount = 0;
    this.halfWayMessageShown = false;
  }

  public ngOnInit(): void {
    this.version = environment.version;
    this.buildNumber = environment.buildNumber;
    this.isDebugMode$$ = this.settingsStore
      .select(selectIsDebugMode)
      .subscribe((isDebugMode) => (this.debugEnabled = isDebugMode));
  }

  public ngOnDestroy() {
    this.isDebugMode$$.unsubscribe();
  }
}
