import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ISettingState, selectApiUrl } from '../settings/settings.reducer';
import { switchMap, take } from 'rxjs/operators';
import { INotification } from '@qld-recreational/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private settingsStore: Store<ISettingState>
  ) {}

  public loadNewNotifications(lastLoadedDateTime: string) {
    return this.settingsStore.select(selectApiUrl).pipe(
      take(1),
      switchMap((apiUrl) =>
        this.http.get<INotification[]>(`${apiUrl}/notification`, {
          params: { lastLoadedDateTime },
        })
      )
    );
  }
}
