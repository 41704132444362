import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { loadFavourites, toggleFavourite } from './species.actions';
import { append, includes, without } from '@qld-recreational/ramda';
import { deleteAccountSuccess, switchUser } from '../auth/auth.actions';
import { switchEnv } from '../settings/settings.actions';

export const speciesFeatureKey = 'species';

export interface ISpeciesState {
  favouriteSpecies: Array<number>;
}

export const initialState: ISpeciesState = {
  favouriteSpecies: [],
};

export const reducer = createReducer(
  initialState,
  on(loadFavourites, (state, action) => ({
    ...state,
    favouriteSpecies: Array.from(new Set(action.caabCodes)),
  })),
  on(toggleFavourite, (state, action) => {
    let favouriteSpecies = [...state.favouriteSpecies];
    favouriteSpecies = includes(action.caabCode, favouriteSpecies)
      ? without([action.caabCode], favouriteSpecies)
      : append(action.caabCode, favouriteSpecies);
    return { favouriteSpecies };
  }),
  on(switchUser, switchEnv, deleteAccountSuccess, () => initialState)
);

export const selectSpeciesState =
  createFeatureSelector<ISpeciesState>(speciesFeatureKey);

export const selectFavouriteSpecies = createSelector(
  selectSpeciesState,
  (state) => state.favouriteSpecies
);
