import { Component, ChangeDetectorRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  IAppState,
  selectHasAcceptedDisclaimer,
  selectHasOnBoarded,
} from './app-state/app-state.reducer';
import { merge } from 'rxjs';
import { take, withLatestFrom, startWith, filter } from 'rxjs/operators';
import { loadNewNotifications } from './notification/notification.actions';
import { INotificationState } from './notification/notification.reducer';
import { selectNotificationsLastLoadedDateTime } from './notification/notification.selectors';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';
import { Device } from '@capacitor/device';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from '../environments/environment';
import { register } from 'swiper/element/bundle';
import { runIfNative } from './shared/runIfNative';
import { includes } from 'rambda';
import { IAuthState, selectUserID } from './auth/auth.reducer';
import { equals } from '@qld-recreational/ramda';
import { App } from '@capacitor/app';
import { GoogleAnalyticsService } from './shared/google-analytics.service';
import { selectOverrideTestToolbar } from './settings/settings.reducer';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public nonProd = !environment.production;
  public overrideTestToolbar$ = this.appStateStore.select(
    selectOverrideTestToolbar
  );

  constructor(
    private platform: Platform,
    private router: Router,
    private appStateStore: Store<IAppState>,
    private notificationStore: Store<INotificationState>,
    private cdRef: ChangeDetectorRef,
    private authStore: Store<IAuthState>,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.initializeApp();
  }

  private async initializeApp() {
    if (!environment.production) {
      runIfNative(() => {
        App.getInfo().then((info) => {
          console.log('App info', ...Object.values(info));
        });
      });
    }

    await this.googleAnalyticsService.initGoogleAnalytics();

    await this.platform.ready();
    runIfNative(
      () => Keyboard.setAccessoryBarVisible({ isVisible: true }),
      'ios'
    );
    await this.setupStatusBar();
    this.navigateByAppState();

    runIfNative(() => this.initOneSignal());
  }

  private initOneSignal() {
    // Enable to debug issues:
    OneSignal.setLogLevel(6, 0);

    OneSignal.setAppId(environment.oneSignalAppId);

    OneSignal.setNotificationWillShowInForegroundHandler(
      (notificationEvent) => {
        notificationEvent.complete(notificationEvent.getNotification());
      }
    );

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using
    //    an In-App Message to prompt for notification permission (See step 7) to better communicate
    //    to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log('User accepted notifications: ' + accepted);
    });

    OneSignal.promptLocation();

    this.syncNotifications();

    this.authStore.select(selectUserID).subscribe((userId) => {
      if (userId) {
        OneSignal.setExternalUserId(userId);
      } else {
        OneSignal.removeExternalUserId();
      }
    });
  }

  private syncNotifications() {
    merge(
      this.platform.resume,
      new Promise((resolve) => {
        OneSignal.setNotificationWillShowInForegroundHandler(
          (notificationEvent) => {
            resolve(notificationEvent.getNotification());
          }
        );
      })
    )
      .pipe(
        startWith('Platform is ready'),
        withLatestFrom(
          this.notificationStore.select(selectNotificationsLastLoadedDateTime)
        )
      )
      .subscribe(([_, lastLoadedDateTime]) => {
        this.notificationStore.dispatch(
          loadNewNotifications({ lastLoadedDateTime })
        );
        this.cdRef.detectChanges();
      });
  }

  public async setupStatusBar() {
    runIfNative(async () => {
      try {
        await StatusBar.setStyle({ style: Style.Dark });
        const device = await Device.getInfo();
        if (device.operatingSystem === 'android') {
          await StatusBar.setOverlaysWebView({ overlay: false });
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  private readonly noNavigatePaths = ['/login'];

  private navigateByAppState() {
    if (includes(location.pathname, this.noNavigatePaths)) {
      return;
    }

    this.appStateStore
      .select(selectHasAcceptedDisclaimer)
      .pipe(
        take(1),
        withLatestFrom(this.appStateStore.select(selectHasOnBoarded))
      )
      .subscribe(([hasAcceptedDisclaimer, hasOnBoarded]) =>
        !hasAcceptedDisclaimer
          ? this.router.navigateByUrl('disclaimer', { replaceUrl: true })
          : this.router.navigateByUrl(hasOnBoarded ? 'sync' : 'on-boarding', {
              replaceUrl: true,
            })
      );
  }
}
