import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { isNil } from '@qld-recreational/ramda';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storageAngular: Storage) {}

  public get storage() {
    if (isNil(this._storage)) {
      throw new Error('Storage not initialized');
    }
    return this._storage;
  }

  public async init() {
    if (isNil(this._storage)) {
      await this.storageAngular.defineDriver(CordovaSQLiteDriver);
      const storage = await this.storageAngular.create();
      this._storage = storage;
    }

    return this._storage;
  }

  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }

  public get(key: string) {
    return this._storage?.get(key);
  }

  public remove(key: string) {
    return this._storage?.remove(key);
  }

  public clear() {
    return this._storage?.clear();
  }
}
