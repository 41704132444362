import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  pairwise,
  startWith,
  take,
} from 'rxjs';
import {
  selectBoatRampPreferences,
  selectSipsPreferences,
} from './preferences.reducer';
import { equals } from 'rambda';
import { IPreferenceLocationType } from '../shared/preference-location/preference-location.model';
import { PreferencesActions } from './preferences.actions';
import { selectFavouriteSpecies } from '../species/species.reducer';
import { AllPreferences } from './preferences.model';
import { isNilOrEmpty } from '@qld-recreational/ramda';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(private store: Store) {}

  public favouritedLocations$ = combineLatest([
    this.store.select(selectBoatRampPreferences),
    this.store.select(selectSipsPreferences),
  ]).pipe(
    map(([boatramps, sips]) => [...boatramps, ...sips]),
    distinctUntilChanged(equals<(string | number)[]>)
  );

  public favouritedLocationsDiff$ = this.favouritedLocations$.pipe(
    startWith([] as (string | number)[]),
    pairwise(),
    map(([prev, cur]) => {
      // find added items
      const added = cur.filter((item) => !prev.includes(item));
      // find removed items
      const removed = prev.filter((item) => !cur.includes(item));

      return {
        added,
        removed,
      };
    })
  );

  public toggleLocationPreference(
    id: string | number,
    type: IPreferenceLocationType
  ) {
    this.store.dispatch(
      PreferencesActions.toggleLocation({
        id,
        preferenceType: type,
      })
    );
  }

  public fromPayload(payload: string): AllPreferences | null {
    if (isNilOrEmpty(payload)) {
      return null;
    }
    return JSON.parse(payload);
  }

  public toPayload(preferences: AllPreferences): string {
    return JSON.stringify(preferences);
  }

  public getCurrentPreferences(): Observable<AllPreferences> {
    return combineLatest([
      this.store.select(selectBoatRampPreferences),
      this.store.select(selectSipsPreferences),
      this.store.select(selectFavouriteSpecies),
    ]).pipe(
      map(([boatramps, sips, species]) => ({
        boatramps: boatramps || [],
        sips: sips || [],
        species: species || [],
      }))
    );
  }
}
