import { createFeature, createReducer, on } from '@ngrx/store';
import { PreferencesActions } from './preferences.actions';
import { complement, equals, isNil } from '@qld-recreational/ramda';
import { IPreferenceLocationType } from '../shared/preference-location/preference-location.model';
import { ViewStatus } from '../shared/ViewStatus';
import { deleteAccountSuccess, switchUser } from '../auth/auth.actions';

export interface IPreferencesState {
  hasChange: boolean;
  viewStatus: ViewStatus;
  boatramps: (string | number)[];
  sips: (string | number)[];
}

export const initialState: IPreferencesState = {
  viewStatus: ViewStatus.Initial,
  hasChange: false,
  boatramps: [],
  sips: [],
};

function getStateKey(type: IPreferenceLocationType) {
  switch (type) {
    case IPreferenceLocationType.BoatRamp:
      return 'boatramps';
    case IPreferenceLocationType.SIP:
      return 'sips';
    default:
      return null;
  }
}

export const preferencesFeaturePersistenceKeys = Object.keys(
  initialState
).filter(complement(equals('viewStatus')));

const reducer = createReducer(
  initialState,
  on(PreferencesActions.preferencesChanged, (state) => ({
    ...state,
    hasChange: true,
  })),
  on(PreferencesActions.attemptSavePreferences, (state) => ({
    ...state,
    hasChange: false, // so that if any other preferencesChanged happens WHILE we're submitting, we don't lose the change and attempt to save again
    viewStatus: ViewStatus.Loading,
  })),
  on(PreferencesActions.savePreferencesSuccess, (state) => ({
    ...state,
    viewStatus: ViewStatus.Success,
  })),
  on(PreferencesActions.savePreferencesFailure, (state) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    hasChange: true, // to make sure to attempt again
  })),
  on(PreferencesActions.loadLocations, (state, { ids, preferenceType }) => {
    const stateKey = getStateKey(preferenceType);
    if (isNil(stateKey)) {
      return state;
    }
    return {
      ...state,
      [stateKey]: Array.from(new Set(ids)),
    };
  }),
  on(PreferencesActions.deleteLocation, (state, { id, preferenceType }) => {
    const stateKey = getStateKey(preferenceType);
    if (isNil(stateKey)) {
      return state;
    }
    return {
      ...state,
      [stateKey]: state[stateKey].filter(complement(equals(id))),
    };
  }),
  on(PreferencesActions.toggleLocation, (state, { id, preferenceType }) => {
    const stateKey = getStateKey(preferenceType);
    if (isNil(stateKey)) {
      return state;
    }
    const curLocations = new Set(state[stateKey]);
    if (curLocations.has(id)) {
      curLocations.delete(id);
    } else {
      curLocations.add(id);
    }

    return {
      ...state,
      [stateKey]: Array.from(curLocations),
    };
  }),
  on(switchUser, deleteAccountSuccess, () => initialState)
);

const preferencesFeature = createFeature({
  name: 'preferences',
  reducer,
});

export const {
  name: preferencesFeatureKey,
  reducer: preferencesReducer,
  selectBoatramps: selectBoatRampPreferences,
  selectSips: selectSipsPreferences,
  selectHasChange: selectPreferencesHasChange,
  selectViewStatus: selectPreferencesViewStatus,
} = preferencesFeature;
