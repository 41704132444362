import { Injectable, isDevMode } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { IAuthState, IUser, selectUserID } from './auth.reducer';
import {
  ISettingState,
  selectDeleteAccountUrl,
  selectTargetEnvs,
} from '../settings/settings.reducer';
import {
  AuthConnect,
  AuthResult,
  AzureProvider,
  ProviderOptions,
  TokenType,
} from '@ionic-enterprise/auth';
import { not } from 'rambda';
import {
  Observable,
  take,
  map,
  from,
  of,
  switchMap,
  tap,
  catchError,
  BehaviorSubject,
  filter,
  withLatestFrom,
  throwError,
  retry,
  timer,
  concatMap,
  shareReplay,
  timeout,
} from 'rxjs';
import {
  AuthTypes,
  clearTokens,
  getAuthResponse,
  ionicAuthOptions,
  storeTokens,
} from './auth';
import { isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import { logout } from './auth.actions';
import { JwtPayload } from 'jwt-decode';
import { RedirectService } from '../shared/redirect.service';
import { LoginReminderModalComponent } from '../login-reminder-modal/login-reminder-modal.component';

const refreshTokenExpiredErrorMessage = 'grant has expired';
const refreshTokenInvalidGrant = 'invalid_grant';
const refreshTokenIsNotActive = 'authentication error: token is not active';
const refreshTokenNotAvailable = 'refresh token is not available';
const refreshTokenUnabletoRefresh = 'unable to refresh session';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private platform: Platform,
    private authStore: Store<IAuthState>,
    private settingsStore: Store<ISettingState>,
    private redirectService: RedirectService,
    private modalController: ModalController,
    private navController: NavController
  ) {}

  private provider = new AzureProvider();
  private authResult: AuthResult;

  public async init() {
    await AuthConnect.setup({
      platform: this.platform.is('hybrid') ? 'capacitor' : 'web',
      logLevel: isDevMode() ? 'DEBUG' : 'ERROR',
      ios: {
        webView: 'private',
      },
      web: {
        authFlow: 'PKCE',
        uiMode: 'popup',
      },
    });

    this.authResult = await getAuthResponse();
  }

  private getProviderOptions(authType: AuthTypes) {
    return this.settingsStore.select(selectTargetEnvs).pipe(
      take(1),
      switchMap((envs) =>
        ionicAuthOptions(authType, envs, this.platform.is('hybrid'))
      )
    );
  }

  public getAccessToken() {
    if (isNil(this.authResult)) {
      return of(null);
    }

    return from(AuthConnect.isAccessTokenExpired(this.authResult)).pipe(
      switchMap((isAccessTokenExpired) => {
        if (!isAccessTokenExpired) {
          return this.getAccessTokenFromResult(this.authResult);
        }

        return this.refreshSession().pipe(
          switchMap(() => this.getAccessTokenFromResult(this.authResult)),
          catchError((error) => {
            console.log(error);
            if (
              typeof error?.message === 'string' &&
              (error.message.toLowerCase().startsWith('token error') ||
                [
                  refreshTokenExpiredErrorMessage,
                  refreshTokenInvalidGrant,
                  refreshTokenIsNotActive,
                  refreshTokenNotAvailable,
                  refreshTokenUnabletoRefresh,
                ].some((message) =>
                  error.message.toLowerCase().includes(message)
                ))
            ) {
              this.authStore.dispatch(logout());
            }
            return of(null);
          })
        );
      })
    );
  }

  public getUserId() {
    return this.authStore.select(selectUserID);
  }

  private handleSuccessfulAuth(authResult: AuthResult) {
    this.authResult = authResult;
    return storeTokens(authResult);
  }

  public refreshSession() {
    return timer(1).pipe(
      concatMap(() =>
        AuthConnect.refreshSession(this.provider, this.authResult)
      ),
      retry(2),
      switchMap((result) => this.handleSuccessfulAuth(result))
    );
  }

  public getAccessTokenFromResult(authResult: AuthResult) {
    if (!authResult) {
      return null;
    }

    return AuthConnect.getToken(TokenType.access, authResult);
  }

  public getUserDetails(): Observable<IUser> {
    if (isNil(this.authResult)) {
      return of(null);
    }

    return from(
      AuthConnect.decodeToken<JwtPayload & { emails: [string] }>(
        TokenType.id,
        this.authResult
      )
    ).pipe(
      map(({ sub, emails }) => ({
        id: sub,
        email: emails[0],
      }))
    );
  }

  public auth(authType: AuthTypes) {
    return this.getProviderOptions(authType).pipe(
      take(1),
      switchMap((options) =>
        from(AuthConnect.login(this.provider, options)).pipe(
          switchMap((result) => this.handleSuccessfulAuth(result))
        )
      )
    );
  }

  private async attemptDeleteAccount(urlToGo: string, redirectUrl: string) {
    let attempt = 0;
    const maxAttempts = 3;

    // IOS has an weird issue where when we try to take the user to the delete account page, the login before the delete account page redirects the user back to the app
    // inexplicably with the "state" query parameter. This, to me, resembles the response from normal login workflow so the authentication that happens on the delete
    // account page somehow redirects to the app instead of the delete account page. Further, this issue only happens on the first attempt of delete account per each login.
    // Subsequent attempts work correctly. So, in the interest of not wasting too much time, we're just attempting to go to the delete account page multiple times on IOS
    while (attempt < maxAttempts) {
      const searchParams = await this.redirectService.showUrl(
        urlToGo,
        redirectUrl
      );

      if (searchParams.has('state')) {
        attempt++;
        continue;
      }

      return;
    }
    return;
  }

  public deleteAccount() {
    return this.getProviderOptions(AuthTypes.DeleteAccount).pipe(
      withLatestFrom(this.settingsStore.select(selectDeleteAccountUrl)),
      switchMap(([options, deleteUrl]) =>
        this.attemptDeleteAccount(deleteUrl, options.redirectUri)
      )
    );
  }

  public clear() {
    this.authResult = null;
    clearTokens();
  }

  public logout() {
    if (isNil(this.authResult)) {
      return of(null);
    }

    return timer(1).pipe(
      concatMap(() =>
        from(AuthConnect.logout(this.provider, this.authResult)).pipe(
          catchError((error) => {
            console.log('logout error', error);
            return of(null);
          })
        )
      ),
      retry(2),
      tap(() => clearTokens()),
      tap(() => (this.authResult = null))
    );
  }

  get isLoggedIn$() {
    return this.authStore
      .select(selectUserID)
      .pipe(map((email) => not(isNilOrEmpty(email))));
  }

  public redirectToLogin(redirectUrl: string) {
    const search = new URLSearchParams({ redirectUrl });
    this.navController.navigateForward('login' + '?' + search.toString(), {
      replaceUrl: true,
    });
  }

  private readonly LOGIN_REMINDER_MODAL_ID = 'rec-login-reminder-modal';
  public async showLoginReminderModal(message?: string) {
    try {
      await this.modalController.dismiss(
        null,
        null,
        this.LOGIN_REMINDER_MODAL_ID
      );
    } catch (e) {
      // ignore error,
      // if the overlay doesn't already exist, it will error out. We don't care
    }

    const modal = await this.modalController.create({
      component: LoginReminderModalComponent,
      backdropDismiss: true,
      showBackdrop: true,
      handle: false,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      id: this.LOGIN_REMINDER_MODAL_ID,
      cssClass: this.LOGIN_REMINDER_MODAL_ID,
      componentProps: {
        message,
      },
    });

    await modal.present();
  }
}
