import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { networkConnected } from '@qld-recreational/network';
import { from, of, switchMap } from 'rxjs';
import { IAuthState, selectUserID } from '../auth/auth.reducer';
import {
  IBackgroundRequestState,
  selectNumBackgroundRequests,
} from './background-request.reducer';

@Injectable({
  providedIn: 'root',
})
export class BackgroundRequestService {
  constructor(
    private authStore: Store<IAuthState>,
    private backgroundRequestsStore: Store<IBackgroundRequestState>
  ) {}

  public checkConnectivity() {
    return from(networkConnected());
  }

  public numBackgroundRequests$ = this.authStore
    .select(selectUserID)
    .pipe(
      switchMap((userId) =>
        userId
          ? this.backgroundRequestsStore.select(selectNumBackgroundRequests)
          : of(0)
      )
    );
}
