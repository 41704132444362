import { WeatherElement } from '@qld-recreational/rec-api';

type WeatherDataKeys =
  | 'air_temperature'
  | 'msl_pres'
  | 'qnh_pres'
  | 'wind_dir'
  | 'wind_dir_deg'
  | 'wind_spd'
  | 'wind_spd_kmh'
  | 'swell_height'
  | 'moon_phase'
  | 'rainfall'
  | 'cloud';
export type WeatherData = Partial<Record<WeatherDataKeys, WeatherElement>>;

type TideDataKeys = 'tide_forecast_high' | 'tide_forecast_low';
export type TideData = Partial<Record<TideDataKeys, WeatherElement[]>>;

export enum MoonPhases {
  ['New moon'] = 1,
  ['Waxing crescent'],
  ['First quarter'],
  ['Waxing gibbous'],
  ['Full moon'],
  ['Waning gibbous'],
  ['Third quarter'],
  ['Waning crescent'],
}
