import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { MESSAGES } from '../messages';
import { timer } from 'rxjs';

@Component({
  selector: 'qld-recreational-login-reminder-modal',
  templateUrl: './login-reminder-modal.component.html',
  styleUrls: ['./login-reminder-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class LoginReminderModalComponent {
  @Input() message = MESSAGES.submitAuthReminderMessage;

  constructor(
    private modalController: ModalController,
    private navController: NavController
  ) {}

  public doItLater() {
    this.modalController.dismiss();
  }

  public goToLogin() {
    this.navController.navigateForward('/login');

    timer(100).subscribe(() => {
      this.modalController.dismiss(true);
    });
  }
}
