import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  NGRX_STORAGE_SYNC_IGNORE_ACTIONS,
  saveState,
} from './ngrx-ionic-storage-sync';
import { StorageService } from '@qld-recreational/storage';
import { Store } from '@ngrx/store';
import { settingsFeatureKey } from '../settings/settings.reducer';
import { speciesFeatureKey } from '../species/species.reducer';
import { appStateFeatureKey } from '../app-state/app-state.reducer';
import { notificationsFeatureKey } from '../notification/notification.reducer';
import { authFeatureKey, authPersistenceKeys } from '../auth/auth.reducer';
import { reportsFeatureKey } from '../reports/reports.reducer';
import {
  backgroundRequestFeatureKey,
  backgroundRequestPersistenceKeys,
} from '../background-request/background-request.reducer';
import {
  reportListFeatureKey,
  reportListPersistenceKeys,
} from '../report-list/report-list.reducer';
import {
  preferencesFeatureKey,
  preferencesFeaturePersistenceKeys,
} from '../preferences/preferences.reducer';
import { reportDiaryFeatureKey } from '../report-diary/report-diary.reducer';

const keys = [
  { [authFeatureKey]: authPersistenceKeys },
  { [preferencesFeatureKey]: preferencesFeaturePersistenceKeys },
  settingsFeatureKey,
  speciesFeatureKey,
  appStateFeatureKey,
  notificationsFeatureKey,
  reportsFeatureKey,
  { [backgroundRequestFeatureKey]: backgroundRequestPersistenceKeys },
  { [reportListFeatureKey]: reportListPersistenceKeys },
  reportDiaryFeatureKey,
];

@Injectable()
export class StorageSyncEffects {
  constructor(
    private actions$: Actions,
    private storage: StorageService,
    private store: Store<{}>
  ) {}

  public saveState$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.store.select((store) => store)),
        tap(([action, nextState]) => {
          if (!NGRX_STORAGE_SYNC_IGNORE_ACTIONS.includes(action.type)) {
            const storage = this.storage.storage;
            saveState(storage, nextState, keys).catch((err) =>
              console.log(err)
            );
          }
        })
      ),
    {
      dispatch: false,
    }
  );
}
