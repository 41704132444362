import { Injectable } from '@angular/core';
import {
  equals,
  groupBy,
  isNilOrEmpty,
  pick,
  prop,
  uniq,
} from '@qld-recreational/ramda';
import {
  Point,
  centerMedian,
  featureCollection,
  point,
  nearestPoint,
  FeatureCollection,
  Feature,
} from '@turf/turf';
import { Location, Weather, WeatherElement } from '@qld-recreational/rec-api';
import moment from 'moment';
import { IGETReport, IReportWeather } from '../report-list/report-list.model';
import { TripTypes } from './model';
import { MoonPhases, TideData, WeatherData } from './environmental.data.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentalDataService {
  /**
   * Pick the geographically closest weather station to the given location
   * @param location
   * @param stations
   * @returns
   */
  private pickNearestWeatherStation(
    location: Point,
    stations: Weather[]
  ): Feature<
    Point,
    Pick<Weather, 'elements' | 'station'> & {
      featureIndex: number;
      distanceToPoint: number;
    }
  > {
    const stationsCollection = this.toFeatureCollection(stations, [
      'elements',
      'station',
    ]);

    return nearestPoint(location, stationsCollection);
  }

  private toFeatureCollection<
    T extends Pick<Location, 'latitude' | 'longitude'>,
    K extends keyof T
  >(locations: T[], keys: K | K[] = []) {
    return featureCollection(
      locations.map((location) =>
        point(
          [location.longitude, location.latitude],
          pick(Array.isArray(keys) ? keys : ([keys] as K[]), location)
        )
      )
    );
  }

  /**
   * The API returns some weather stations that have the same lat long but different capitalisation of names probably due to it being different data sources.
   *
   * If there are multiple stations with the same lat long, we should only use one of them and aggregate the elements
   * @param weatherStations
   * @returns
   */
  private dedupWeatherStations(weatherStations: Weather[]): Weather[] {
    const grouped = groupBy(this.stringifyWeatherStation, weatherStations);

    return Object.values(grouped).map(
      (group): Weather => ({
        station: group[0].station,
        latitude: group[0].latitude,
        longitude: group[0].longitude,
        elements: group.flatMap((station) => station.elements),
      })
    );
  }

  /**
   * Stringify weather stations to identify them uniquely
   * @param station
   * @returns
   */
  private stringifyWeatherStation(station: Weather): string {
    return `(${station.latitude}, ${station.longitude})`;
  }

  /**
   * Then we specifically deal with the tide forecasts because they work a bit differently since there are 2 per day
   * @param tideElements
   * @returns
   */
  private transformTideElements(tideElements: WeatherElement[]): TideData {
    return groupBy(prop('elementType'), tideElements) as TideData;
  }

  /**
   * Pick the closest weather element to the trip time
   * @param dateTime
   * @param elements
   * @returns
   */
  private pickClosestWeatherElement(
    dateTime: Date,
    elements: WeatherElement[]
  ) {
    let distance = Infinity;
    let el: WeatherElement = null;
    for (const element of elements) {
      const newDistance = Math.abs(
        new Date(dateTime).getTime() -
          new Date(element.elementDateTime).getTime()
      );
      if (newDistance < distance) {
        distance = newDistance;
        el = element;
      }
    }

    return el;
  }

  /**
   * The API currently returns weather information from a bunch of different times AROUND the trip time
   *
   * Below logic groups the entries by the element type and for each element, picks the closest one to the trip time
   * @param weatherElements
   * @param dateTime
   * @returns
   */
  private transformWeatherElements(
    weatherElements: WeatherElement[],
    dateTime: Date
  ): WeatherData {
    const groupWeatherElements = groupBy(prop('elementType'), weatherElements);

    const weather = Object.fromEntries(
      Object.entries(groupWeatherElements).map(([key, value]) => [
        key,
        this.pickClosestWeatherElement(dateTime, value),
      ])
    );

    return weather as WeatherData;
  }

  /**
   * Decide which point to use as reference for the weather station
   * @param report
   * @returns
   */
  private pickLocationToUse(report: IGETReport): Point {
    const tripType = TripTypes[report.platform];
    if (equals(tripType, TripTypes.Shore)) {
      // Center median of fishing locations
      const fishingLocations = report?.fishingLocations ?? [];

      const center = centerMedian(this.toFeatureCollection(fishingLocations));

      return center.geometry;
    }

    // Use return location directly for Private and Charter trips
    return point([
      report?.returnLocation?.longitude,
      report?.returnLocation?.latitude,
    ]).geometry;
  }

  private getMoonPhase(element: WeatherElement) {
    const numValue = Number(element.elementValue);

    switch (numValue) {
      case 0:
        return MoonPhases['New moon'];
      case -0.5:
        return MoonPhases['First quarter'];
      case 1:
        return MoonPhases['Full moon'];
      case 0.5:
        return MoonPhases['Third quarter'];
      default:
        return null;
    }
  }

  private inrementMoonPhase(moonPhase: MoonPhases, direction: 'next' | 'prev') {
    const newPhase = direction === 'next' ? moonPhase + 1 : moonPhase - 1;
    if (newPhase < MoonPhases['New moon']) {
      return newPhase + MoonPhases['Waning crescent'];
    }

    if (newPhase > MoonPhases['Waning crescent']) {
      return newPhase - MoonPhases['Waning crescent'];
    }

    return newPhase;
  }

  private transformMoonPhase(
    element: WeatherElement,
    reportDateTime: Date
  ): WeatherElement {
    if (isNilOrEmpty(element)) {
      return null;
    }

    let moonPhase = this.getMoonPhase(element);
    const reportDate = moment(reportDateTime);

    const readingDate = moment(element.elementDateTime);

    if (reportDate.isAfter(readingDate, 'day')) {
      moonPhase = this.inrementMoonPhase(moonPhase, 'next');
    } else if (reportDate.isBefore(readingDate, 'day')) {
      moonPhase = this.inrementMoonPhase(moonPhase, 'prev');
    }

    return {
      ...element,
      elementValue: moonPhase?.toString(),
    };
  }

  public transformWeatherStation(report: IGETReport): IReportWeather {
    if (isNilOrEmpty(report?.weather)) {
      return {
        station: 'XX',
        weather: {},
        tide: {},
      };
    }
    const weatherStations = report?.weather ?? [];

    const center = this.pickLocationToUse(report);

    const nearest = this.pickNearestWeatherStation(
      center,
      this.dedupWeatherStations(weatherStations)
    );

    const allWeatherElementsForStation = uniq(nearest.properties.elements);

    const tireForecastPrefix = 'tide_forecast';

    const reportDate = report.returnDateTime;

    const weather = this.transformWeatherElements(
      allWeatherElementsForStation.filter(
        (element) => !element.elementType.startsWith(tireForecastPrefix)
      ),
      reportDate
    );

    const tide = this.transformTideElements(
      allWeatherElementsForStation.filter((element) =>
        element.elementType.startsWith(tireForecastPrefix)
      )
    );

    const weatherFromALLStations = this.transformWeatherElements(
      weatherStations.flatMap((station) => station.elements),
      reportDate
    );

    return {
      station: nearest.properties.station,
      weather: {
        ...weather,
        moon_phase: this.transformMoonPhase(
          weatherFromALLStations.moon_phase,
          reportDate
        ),
      },
      tide,
    };
  }
}
