import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as NotificationActions from './notification.actions';
import { INotification } from '@qld-recreational/models';
import { without } from '@qld-recreational/ramda';
import * as moment from 'moment';

export const notificationsFeatureKey = 'notifications';

export interface INotificationState extends EntityState<INotification> {
  readNotificationIds: string[];
  lastLoadedDateTime?: string;
}

export const adapter: EntityAdapter<INotification> = createEntityAdapter<
  INotification
>({
  selectId: (notification) => notification.id,
  sortComparer: (notificationA, notificationB) =>
    moment(notificationA.dateTime).isBefore(moment(notificationB.dateTime))
      ? 1
      : -1,
});

export const initialState: INotificationState = adapter.getInitialState({
  readNotificationIds: [],
  lastLoadedDateTime: moment().toISOString(),
});

export const reducer = createReducer(
  initialState,
  on(NotificationActions.deleteNotification, (state, action) =>
    adapter.removeOne(action.id, {
      ...state,
      readNotificationIds: without([action.id], state.readNotificationIds),
    })
  ),
  on(
    NotificationActions.setReadNotificationIds,
    (state, { readNotificationIds }) => ({
      ...state,
      readNotificationIds,
    })
  ),
  on(
    NotificationActions.loadNewNotificationsSuccess,
    (state, { notifications }) =>
      adapter.addMany(notifications, {
        ...state,
        lastLoadedDateTime: moment().toISOString(),
      })
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
