import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadNewNotifications,
  loadNewNotificationsSuccess,
  loadNewNotificationsFailure,
} from './notification.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { of } from 'rxjs';

@Injectable()
export class NotificationEffects {
  public loadNewNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNewNotifications),
      switchMap(({ lastLoadedDateTime }) =>
        this.notificationService.loadNewNotifications(lastLoadedDateTime).pipe(
          map((notifications) =>
            loadNewNotificationsSuccess({ notifications })
          ),
          catchError(() => of(loadNewNotificationsFailure()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
}
