import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IReportDiaryState } from './report-diary.reducer';
import { CatchTypeFilter, TimeFilter } from '../shared/filter.model';

export const ReportDiaryActions = createActionGroup({
  source: 'Report Diary',
  events: {
    Init: emptyProps(),
    'Change Time Filter': props<{ timeFilter: TimeFilter }>(),
    'Change Catch Type Filter': props<{ catchTypeFilter: CatchTypeFilter }>(),
  },
});
