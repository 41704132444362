import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { EnvsService } from '../shared/service/envs.service';
import { loadEnvs } from './settings.actions';
import { StorageSyncActions } from '../reducers/ngrx-ionic-storage-sync';

@Injectable()
export class SettingsEffects {
  public loadEnvs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATED),
      switchMap(() => this.envsService.load()),
      map((env) => loadEnvs({ env }))
    )
  );

  constructor(private actions$: Actions, private envsService: EnvsService) {}
}
