import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvMap } from '../../settings/settings';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EnvsService {
  constructor(private http: HttpClient) {}

  public load = (): Observable<EnvMap> =>
    this.http.get<EnvMap>('assets/envs-app.json');
}
