import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { switchMap, take } from 'rxjs';
import { selectReportingApiUrl } from '../../settings/settings.reducer';
import { Store } from '@ngrx/store';
import { AuthService } from '../../auth/auth.service';
import { isNilOrEmpty } from '@qld-recreational/ramda';

export const TokenInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  const settingStore = inject(Store);
  const reportingBaseUrl$ = settingStore.select(selectReportingApiUrl);
  return reportingBaseUrl$.pipe(
    take(1),
    switchMap((reportingBaseUrl) => {
      if (!request.url.includes(reportingBaseUrl)) {
        return next(request);
      }

      return authService.getAccessToken().pipe(
        take(1),
        switchMap((token) => {
          if (isNilOrEmpty(token)) {
            return next(request);
          }
          const transformedRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next(transformedRequest);
        })
      );
    })
  );
};
