import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { getId, ISideMenuItem } from '../../contentful/contentful';
import { StorageService } from '@qld-recreational/storage';

@Component({
  selector: 'qld-recreational-side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuItemComponent implements OnInit {
  @Input() public item: ISideMenuItem;
  public itemImgSrc: string;

  constructor(
    private router: Router,
    private storage: StorageService,
    private cdr: ChangeDetectorRef
  ) {}

  public async ngOnInit() {
    this.itemImgSrc = await this.storage.get(getId(this.item.icon));
    this.cdr.detectChanges();
  }
}
