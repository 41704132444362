import { createAction, props } from '@ngrx/store';
import { IUser } from './auth.reducer';
import { AuthTypes } from './auth';

export const authInit = createAction('[Auth] Init');

export const auth = createAction(
  '[Auth] Auth',
  props<{ authType: AuthTypes }>()
);

export const authWithRedirect = createAction(
  '[Auth] Auth with redirect',
  props<{ redirectUrl?: string; authType: AuthTypes }>()
);

export const authSuccess = createAction(
  '[Auth] Auth Success',
  props<IUser & { redirectUrl?: string }>()
);

export const authFailure = createAction(
  '[Auth] Auth Failed',
  props<{ error: string }>()
);

export const switchUser = createAction('[Auth] Switch User');

export const logout = createAction('[Auth] Logout');

export const logoutSuccess = createAction('[Auth] Logout Success');

export const logoutFailure = createAction(
  '[Auth] Logout Failed',
  props<{ error: string }>()
);

export const deleteAccount = createAction('[Auth] Delete Account');

export const deleteAccountSuccess = createAction(
  '[Auth] Delete Account Success'
);

export const deleteAccountFail = createAction('[Auth] Delete Account Failed');
