import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { defaultIfEmpty, forkJoin } from 'rxjs';
import { Photo } from '@capacitor/camera';

export interface IBackgroundUploaderPayload {
  serverUrl: string;
  filePath: string;
  id: string;
  fileKey: string;
}

export interface IBackgroundUploader {
  startUpload(payload: IBackgroundUploaderPayload): void;

  on(event: 'error', handler: (uploadException: any) => void): void;

  on(
    event: 'progress',
    handler: (uploadStatus: { id: string; progress: number }) => void
  ): void;

  on(event: 'success', handler: (uploadStatus: any) => void): void;

  stopSession(): void;
}

declare const FileTransferManager: {
  init(): IBackgroundUploader;
};

@Injectable()
export class UploadPhotoService {
  public uploader: IBackgroundUploader;

  constructor(private http: HttpClient) {}

  public init(readySource: string) {
    if (readySource !== 'cordova') {
      return;
    }
    this.uploader = FileTransferManager.init();
    this.initOnSuccessListener();
    this.initOnProcessListener();
    this.initOnErrorListener();
  }

  public initOnSuccessListener() {
    this.uploader.on('success', (upload) => {
      console.log('upload: ' + upload.id + ' has been completed successfully');
      console.log(upload.statusCode, upload.serverResponse);
    });
  }

  public initOnProcessListener() {
    this.uploader.on('progress', (upload) => {
      console.log(
        'uploading: ' + upload.id + ' progress: ' + upload.progress + '%'
      );
    });
  }

  public initOnErrorListener() {
    this.uploader.on('error', (uploadException) => {
      if (uploadException.id) {
        console.log('upload: ' + uploadException.id + ' has failed');
        console.error('uploader caught an error: ' + uploadException);
      } else {
        console.error('uploader caught an error: ' + uploadException.error);
      }
    });
  }

  public upload(serverUrl: string, filePath: string, id: string) {
    this.uploader.startUpload(
      this.constructUploadOptions(serverUrl, filePath, id)
    );
  }

  private constructUploadOptions(
    serverUrl: string,
    filePath: string,
    id: string
  ): IBackgroundUploaderPayload {
    return {
      serverUrl,
      filePath,
      id,
      fileKey: 'file',
    };
  }

  public generatePhotoIds = (numberOfImages: number): Array<string> => {
    const id = uuidv4();
    return [...Array(numberOfImages)]
      .map((_, i) => i + 1)
      .map((i) => `${id}-${i}`);
  };

  public uploadPhotos(imgs: Array<Photo>, urls: Array<string>) {
    return forkJoin(
      urls.map((url, i) =>
        this.http.put(url, this.dataURIToBlob(imgs[i].dataUrl))
      )
    ).pipe(defaultIfEmpty([]));
  }

  private dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
}
