import * as moment from 'moment';

export const defaultTimeZone = 'Australia/Sydney';

export const BrisbaneTimeZone = 'Australia/Brisbane';

export { unix, tz } from 'moment-timezone';

export const now = () => moment().format();

export const utcNow = () => moment().utc().format();

export const fromUnixTimestamp = (unixTimestamp: number) =>
  moment(unixTimestamp * 1000).format();

export const qldDate = (date: string) =>
  moment.tz(date, BrisbaneTimeZone).format('ll');

export const qldDateTime = (date: string) =>
  moment.tz(date, BrisbaneTimeZone).format('lll');

export const qldStartDateUTC = (date: string) =>
  moment.tz(date, BrisbaneTimeZone).clone().startOf('day').utc();

export const qldEndDateUTC = (date: string) =>
  moment.tz(date, BrisbaneTimeZone).clone().endOf('day').utc();

export const qldTime = () => moment().tz(BrisbaneTimeZone);

export const qldTimeNow = () =>
  moment().tz(BrisbaneTimeZone).format().split('+')[0];
