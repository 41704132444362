import {
  SelectFishingLocationFeatureLayer,
  SelectRetrieveLocationFeatureLayer,
} from '@qld-recreational/map';
import { ReportFormService } from './report-form.service';
import { Claim } from '@qld-recreational/rec-api';

export interface BlockSelectOption {
  key: string;
  value: number;
  description?: string;
  tag?: string;
}

export enum TripTypes {
  Private,
  Charter,
  Shore,
}

export type IRetrieveLocation = IPresetLocation | ILatLngLocation;
export type IFishingLocation = IPresetLocation | IGridSiteLocation;

export type ISelectedLocation = IRetrieveLocation | IFishingLocation;

type IPresetRetrieveLocation =
  IPresetLocationBase<SelectRetrieveLocationFeatureLayer>;
type IPresetFishingLocation =
  IPresetLocationBase<SelectFishingLocationFeatureLayer>;

export type IPresetLocation = IPresetRetrieveLocation | IPresetFishingLocation;

export interface ILocationBase {
  lat: number;
  lng: number;
}

interface IPresetLocationBase<T> extends ILocationBase {
  type: T;
  name: string;
  id: string | number;
}

export interface ILatLngLocation extends ILocationBase {
  type: 'latlng';
  id?: string; // used if it's a preference
  lat: number;
  lng: number;
}

export interface IGridSiteLocation
  extends Omit<ILatLngLocation, 'type' | 'lat' | 'lng'>,
    ILocationBase {
  type: 'gridsite';
  grid: string;
}

export function getLocationId(location: ILocationIDParts) {
  switch (location.type) {
    case 'latlng':
      return `${location.type}:${location.lat}-${location.lng}`;
    case 'gridsite':
      return `${location.type}:${location.grid}`;
    default:
      return `${location.type}:${location.id}`;
  }
}

export type ILocationIDParts =
  | Omit<ILatLngLocation, 'id'>
  | Omit<IGridSiteLocation, keyof ILocationBase>
  | Omit<IPresetLocation, 'name' | keyof ILocationBase>;

export type ISelectedLocationMap = Map<
  ReturnType<typeof getLocationId>,
  ISelectedLocation
>;

export const tripTypeList: BlockSelectOption[] = [
  {
    key: 'Private boat, jetski, kayak etc.',
    value: TripTypes.Private,
  },
  {
    key: 'Charter boat',
    value: TripTypes.Charter,
  },
  {
    key: 'Shore',
    value: TripTypes.Shore,
  },
];

export enum CatchReportingTypes {
  WholeBoat,
  Individual,
}

export const catchReportingTypeList: BlockSelectOption[] = [
  {
    key: 'I want to record for the whole boat',
    value: CatchReportingTypes.WholeBoat,
    tag: 'Recommended',
    description:
      'This way is most useful to better understand and assess the health of our fish stocks. You can also add your individual catch numbers as well in the trip history section.​',
  },
  {
    key: 'I want to record my individual catch',
    value: CatchReportingTypes.Individual,
    description:
      'This way is useful but information for the whole boat is best. ​',
  },
];

export interface IMapLocation {
  id: string | number;
  name: string;
  lat: number;
  long: number;
}

export type ICatchSpeciesValues = {
  speciesId: string;
  kept: number;
  released: number;
};

export interface ISpeciesDisplayInfo {
  commonName: string;
  imageSrc: string;
}

export interface ISpeciesSelectValue extends ICatchSpeciesValues {
  speciesDisplayInfo: ISpeciesDisplayInfo;
}

export type IReportValues = ReturnType<ReportFormService['initForm']>['value'];

export interface ClaimPayload extends Omit<Claim, 'caabCode'> {
  speciesId: string;
}

export interface ITimescaleChartData {
  x: number | Date;
  y: number;
}
