import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { EnvMap } from './settings';
import {
  disableDebugMode,
  enableDebugMode,
  loadEnvs,
  switchContentfulContext,
  switchEnv,
  toggleTestToolbar,
} from './settings.actions';
import { ContentfulContext } from '../contentful/contentful';
import { environment } from '../../environments/environment';

export const settingsFeatureKey = 'settings';

export interface ISettingState {
  targetEnv: string;
  environments: EnvMap;
  isDebugMode: boolean;
  contentfulContext: ContentfulContext;
  overrideTestToolbar: boolean;
}

export const initialState: ISettingState = {
  targetEnv: environment.targetEnv,
  environments: {},
  isDebugMode: false,
  contentfulContext: environment.contentfulContext as ContentfulContext,
  overrideTestToolbar: false,
};

export const reducer = createReducer(
  initialState,
  on(loadEnvs, (state, action) => ({
    ...state,
    environments: action.env,
  })),
  on(enableDebugMode, (state) => ({
    ...state,
    isDebugMode: true,
  })),
  on(disableDebugMode, (state) => ({
    ...state,
    isDebugMode: false,
  })),
  on(toggleTestToolbar, (state) => ({
    ...state,
    overrideTestToolbar: !state.overrideTestToolbar,
  })),
  on(switchEnv, (state, action) => ({
    ...state,
    targetEnv: action.env,
  })),
  on(switchContentfulContext, (state, action) => ({
    ...state,
    contentfulContext: action.contentfulContext,
  }))
);

export const selectSettingsState =
  createFeatureSelector<ISettingState>(settingsFeatureKey);

export const selectTargetEnvs = createSelector(
  selectSettingsState,
  (state) => state.environments[state.targetEnv]
);

export const selectEnvironments = createSelector(
  selectSettingsState,
  (state) => state.environments
);

export const selectContentfulContext = createSelector(
  selectSettingsState,
  (state) => state.contentfulContext
);

export const selectIsDebugMode = createSelector(
  selectSettingsState,
  (state) => state.isDebugMode
);

export const selectApiUrl = createSelector(
  selectTargetEnvs,
  (env) => env.apiUrl
);

export const selectReportingApiUrl = createSelector(
  selectTargetEnvs,
  (env) => env?.reportingApiUrl
);

export const selectTargetEnv = createSelector(
  selectTargetEnvs,
  (env) => env.targetEnv
);

export const selectOverrideTestToolbar = createSelector(
  selectSettingsState,
  (state) => state.overrideTestToolbar
);

export const selectDeleteAccountUrl = createSelector(
  selectTargetEnvs,
  (env) => env.accountDeleteUrl
);
