import { Capacitor } from '@capacitor/core';
import { equals, isNilOrEmpty } from '@qld-recreational/ramda';

export const runIfNative = (callback: () => void, platform?: string) => {
  if (
    Capacitor.isNativePlatform() &&
    (isNilOrEmpty(platform) || equals(Capacitor.getPlatform(), platform))
  ) {
    return callback();
  }
};
