import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  IReportListState,
  selectReport,
} from '../../report-list/report-list.reducer';

export const reportExistsGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const clientRequestID = route.paramMap.get('clientRequestID');

  const report$ = inject(Store<IReportListState>).select(
    selectReport(clientRequestID)
  );

  return report$.pipe(
    tap((report) => console.log(report)),
    map((report) => !!report || router.parseUrl('report-history'))
  );
};
