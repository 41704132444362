import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ReportsEffects } from './reports.effects';
import { StoreModule } from '@ngrx/store';
import * as fromReportsFeature from './reports.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromReportsFeature.reportsFeatureKey,
      fromReportsFeature.reducer
    ),
    EffectsModule.forFeature([ReportsEffects]),
  ],
})
export class ReportsModule {}
