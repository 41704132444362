export enum ViewStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Success = 'Success',
  Failure = 'Failure',
}

export enum IdentificationStatus {
  Initial = 'Initial',
  Uploading = 'Uploading',
  Detecting = 'Detecting',
  DetectNoFish = 'DetectNoFish',
  Identifying = 'Identifying',
  Failure = 'Failure',
}
