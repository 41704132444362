import { createAction, props } from '@ngrx/store';

export const hasAcceptedDisclaimer = createAction(
  '[App State] Has Accepted Disclaimer'
);

export const hasOnBoarded = createAction('[App State] Has On Board');

export const nextSyncTokenUpdate = createAction(
  '[App State] Next Sync Token Update',
  props<{ nextSyncToken: string }>()
);

export const clearNextSyncToken = createAction(
  '[App State] Clear Next Sync Token'
);

export const hasShownSpeciesDetailInstruction = createAction(
  '[App State] Has Shown Species Detail Instruction'
);

export const hasShownContactFisheriesFormToast = createAction(
  '[App State] Has Shown Contact Fisheries Form Toast'
);

export const hasShownAboutMyFishToast = createAction(
  '[App State] Has Shown About My Fish Toast'
);

export const hasShownSpeciesListInstruction = createAction(
  '[App State] Has Shown Species List Instruction'
);

export const hasShownWhichFishIsThisInstruction = createAction(
  '[App State] Has Shown Which Fish Is This Instruction'
);

export const hasShownCanIFishHereInstruction = createAction(
  '[App State] Has Shown Can I Fish Here Instruction'
);

export const hasShownSwipeToDismissInstruction = createAction(
  '[App State] Has Shown Swipe To Dismiss Instruction'
);

export const hasShownReportHistoryInstruction = createAction(
  '[App State] Has Shown Report History Instruction'
);

export const hasShownReportPageInstruction = createAction(
  '[App State] Has Shown Report Page Instruction'
);

export const hasShownReportDiaryInstruction = createAction(
  '[App State] Has Shown Report Diary Instruction'
);

export const hasShownHomePageReportingInstruction = createAction(
  '[App State] Has Shown Home Page Reporting Instruction'
);
