import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { loadEnvs } from '../settings/settings.actions';
import { from, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAuthState, selectUserID } from '../auth/auth.reducer';
import { isNilOrEmpty } from '@qld-recreational/ramda';
import {
  IBackgroundRequestState,
  selectBackgroundRequests,
  selectIsLoading,
} from './background-request.reducer';
import { BackgroundRequestActions } from './background-request.actions';
import { BackgroundRequestService } from './background-request.service';
import { PendingTransactionService } from '../shared/pending-transaction.service';
import { ApiService } from '../shared/api.service';
import { ViewStatus } from '../shared/ViewStatus';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class BackgroundRequestEffects {
  private readonly SUBMIT_INTERVAL = 5_000;

  /**
   * Step 1
   */
  initInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnvs),
      switchMap(() =>
        timer(0, this.SUBMIT_INTERVAL).pipe(
          withLatestFrom(this.authStore.select(selectUserID)),
          filter(([, email]) => !isNilOrEmpty(email)),
          switchMap(() =>
            this.backgroundRequestStore.select(selectIsLoading).pipe(take(1))
          ),
          filter((isLoading) => !isLoading),
          switchMap(() =>
            this.backgroundRequestStore
              .select(selectBackgroundRequests)
              .pipe(take(1))
          ),
          filter((backgroundRequests) => backgroundRequests.length > 0),
          map((backgroundRequests) =>
            BackgroundRequestActions.attemptBackgroundRequest({
              backgroundRequest: backgroundRequests[0],
            })
          )
        )
      )
    )
  );

  /**
   * Step 2
   */
  public attemptBackgroundRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.attemptBackgroundRequest),
      switchMap(({ backgroundRequest }) =>
        this.backgroundRequestService.checkConnectivity().pipe(
          map((isConnected) =>
            isConnected
              ? BackgroundRequestActions.submitBackgroundRequest({
                  backgroundRequest,
                })
              : BackgroundRequestActions.noConnectionBackgroundRequest()
          )
        )
      )
    )
  );

  /**
   * Step 3a
   */
  public submitBackgroundRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.submitBackgroundRequest),
      this.pendingTransactionService.checkAndPresentPendingTransactionModal(),
      switchMap(({ backgroundRequest }) =>
        this.apiService.sendBackgroundRequest(backgroundRequest).pipe(
          map(() =>
            BackgroundRequestActions.submitBackgroundRequestSuccess({
              id: backgroundRequest.id,
              path: backgroundRequest.path,
            })
          ),
          catchError(async (error) => {
            await this.pendingTransactionService.dismissPendingTransactionModal();
            return BackgroundRequestActions.submitBackgroundRequestFailure({
              backgroundRequest: {
                id: backgroundRequest.id,
                changes: {
                  ...(isNilOrEmpty(error.status) || error.status === 400
                    ? {}
                    : { id: uuidv4() }),
                  payload: backgroundRequest.payload as any,
                  path: backgroundRequest.path,
                  status: ViewStatus.Failure,
                },
              },
            });
          })
        )
      )
    )
  );

  /**
   * Step 4
   */
  public submitBackgroundRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackgroundRequestActions.submitBackgroundRequestSuccess),
      switchMap(({ id }) =>
        from(
          this.pendingTransactionService.dismissPendingTransactionModal()
        ).pipe(
          map(() => BackgroundRequestActions.deleteBackgroundRequest({ id }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private backgroundRequestService: BackgroundRequestService,
    private pendingTransactionService: PendingTransactionService,
    private authStore: Store<IAuthState>,
    private backgroundRequestStore: Store<IBackgroundRequestState>,
    private apiService: ApiService
  ) {}
}
