import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { VersionMismatchService } from './version-mismatch.service';

@Injectable()
export class VersionMismatchGuard implements CanActivate {
  constructor(
    private router: Router,
    private service: VersionMismatchService
  ) {}

  canActivate() {
    return from(this.service.getAppInfo()).pipe(
      map((entry) => entry?.fields.appVersion ?? '0.0.0'),
      map((version) =>
        this.service.clientIsOlder(version)
          ? this.router.parseUrl('version-mismatch')
          : true
      )
    );
  }
}
