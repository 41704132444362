import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { filter, map, tap } from 'rxjs/operators';
import { ReportsActions } from './reports.actions';
import { ToastService } from '@qld-recreational/toast';
import { not } from 'rambda';
import { isNilOrEmpty } from '@qld-recreational/ramda';

@Injectable()
export class ReportsEffects {
  constructor(private actions$: Actions, private toastService: ToastService) {}

  showSavedToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportsActions.saveReportProgress),
        map(({ toastMessage }) => toastMessage),
        filter((toastMessage) => not(isNilOrEmpty(toastMessage))),
        tap((toastMessage) =>
          this.toastService.presentSuccessToast(toastMessage)
        )
      ),
    {
      dispatch: false,
    }
  );
}
