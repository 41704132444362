import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logo',
})
export class LogoPipe implements PipeTransform {
  public transform(socialMedia: string): string {
    return `logo-${socialMedia.toLowerCase()}`;
  }
}
