import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionMismatchService } from './version-mismatch.service';
import { VersionMismatchGuard } from './version-mismatch.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [VersionMismatchService, VersionMismatchGuard],
})
export class VersionMismatchModule {}
