import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  assetsDownloaded,
  checkUpdateFailure,
  retry,
  syncAssetsFailure,
  syncAssetsSuccess,
  syncEntries,
  syncEntriesFailure,
  syncEntriesSuccess,
  syncSkipped,
  updateAssetsCached,
  updateAssetsDownloaded,
  updateAssetsToCache,
  updateEntriesCached,
  updateEntriesToCache,
} from './contentful.actions';
import { ContentfulSyncStatus } from './contentful';
import { complement } from 'rambda';
import { includes, not } from '@qld-recreational/ramda';

export const contentfulFeatureKey = 'contentful';

export interface IContentfulState {
  error: string;
  viewStatus: ContentfulSyncStatus;
  entriesToCache: number;
  entriesCached: number;
  assetsToCache: number;
  assetsDownloaded: number;
  assetsCached: number;
}

export const initialState: IContentfulState = {
  error: undefined,
  viewStatus: ContentfulSyncStatus.Initial,
  entriesToCache: 0,
  entriesCached: 0,
  assetsToCache: 0,
  assetsDownloaded: 0,
  assetsCached: 0,
};

export const reducer = createReducer(
  initialState,
  on(retry, () => initialState),
  on(syncEntries, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.UpdateChecked,
  })),
  on(syncEntriesSuccess, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.EntriesSynced,
  })),
  on(assetsDownloaded, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.AssetsDownloaded,
  })),
  on(syncAssetsSuccess, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.AssetsSynced,
  })),
  on(syncSkipped, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.AssetsSynced,
  })),
  on(
    syncAssetsFailure,
    syncEntriesFailure,
    checkUpdateFailure,
    (state, action) => ({
      ...state,
      error: action.error,
      viewStatus: ContentfulSyncStatus.Failure,
    })
  ),
  on(updateEntriesToCache, (state, action) => ({
    ...state,
    entriesToCache: action.entriesToCache,
  })),
  on(updateEntriesCached, (state) => ({
    ...state,
    entriesCached: state.entriesCached + 1,
  })),
  on(updateAssetsToCache, (state, action) => ({
    ...state,
    assetsToCache: action.assetsToCache,
  })),
  on(updateAssetsDownloaded, (state) => ({
    ...state,
    assetsDownloaded: state.assetsDownloaded + 1,
  })),
  on(updateAssetsCached, (state) => ({
    ...state,
    assetsCached: state.assetsCached + 1,
  }))
);

export const selectContentfulState =
  createFeatureSelector<IContentfulState>(contentfulFeatureKey);

export const selectError = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.error
);

export const selectViewStatus = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.viewStatus
);

export const selectIsSyncing = createSelector(
  selectViewStatus,
  (viewStatus: ContentfulSyncStatus) =>
    not(
      includes(viewStatus, [
        ContentfulSyncStatus.Initial,
        ContentfulSyncStatus.AssetsSynced,
        ContentfulSyncStatus.Failure,
      ])
    )
);

export const selectEntriesToDownload = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.entriesToCache
);

export const selectEntriesDownloaded = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.entriesCached
);

export const selectAssetsToDownload = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.assetsToCache
);

export const selectAssetsDownloaded = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.assetsDownloaded
);

export const selectAssetsCached = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.assetsCached
);
