import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { IPreferenceLocationType } from '../shared/preference-location/preference-location.model';

export const PreferencesActions = createActionGroup({
  source: 'Preferences',
  events: {
    'Load Locations': props<{
      ids: (string | number)[];
      preferenceType: IPreferenceLocationType;
    }>(),
    'Delete Location': props<{
      id: string | number;
      preferenceType: IPreferenceLocationType;
    }>(),
    'Toggle Location': props<{
      id: string | number;
      preferenceType: IPreferenceLocationType;
    }>(),
    //
    'Preferences Changed': emptyProps(),
    'Attempt Save Preferences': emptyProps(),
    'Save Preferences Success': emptyProps(),
    'Save Preferences Failure': emptyProps(),
  },
});
