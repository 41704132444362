import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Store, StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SharedModule } from './shared/shared.module';
import { SideMenuItemComponent } from './side-menu/side-menu-item/side-menu-item.component';
import { PhotoModule } from './photo/photo.module';
import { SettingsModule } from './settings/settings.module';
import { SettingsEffects } from './settings/settings.effects';
import { AppVersionComponent } from './side-menu/app-version/app-version.component';

import * as Sentry from '@sentry/browser';
import { SentryErrorHandler } from './shared/service/sentry.service';
import { AppStateModule } from './app-state/app-state.module';
import { NotificationModule } from './notification/notification.module';
import { ToastModule } from '@qld-recreational/toast';
import { AlertModule } from '@qld-recreational/alert';
import { ContentfulEffects } from './contentful/contentful.effects';
import { ContentfulModule } from './contentful/contentful.module';
import { VersionMismatchModule } from './version-mismatch/version-mismatch.module';
import { AuthModule } from './auth/auth.module';
import { ReportsModule } from './reports/reports.module';
import { BackgroundRequestModule } from './background-request/background-request.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { ReportListModule } from './report-list/report-list.module';
import {
  preferencesFeatureKey,
  preferencesReducer,
} from './preferences/preferences.reducer';
import { reportDiaryModule } from './report-diary/report-diary.module';
import { PreferencesEffects } from './preferences/preferences.effects';
import { SpeciesModule } from './species/species.module';
import { StorageSyncEffects } from './reducers/ngrx-ionic-storage-sync.effects';
import { AuthService } from './auth/auth.service';
import { StorageService } from '@qld-recreational/storage';
import {
  fetchState,
  StorageSyncActions,
} from './reducers/ngrx-ionic-storage-sync';

if (environment.production) {
  Sentry.init({
    dsn: 'https://c0c58bf3b8804671958a1bad5a6d58ea@o264253.ingest.sentry.io/5372806',
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    SideMenuItemComponent,
    AppVersionComponent,
  ],
  imports: [
    BrowserModule,
    ContentfulModule,
    ToastModule,
    AlertModule,
    SettingsModule,
    NotificationModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: true,
      innerHTMLTemplatesEnabled: true,
    }),
    IonicStorageModule.forRoot({
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      StorageSyncEffects,
      ContentfulEffects,
      SettingsEffects,
      PreferencesEffects,
    ]),
    SpeciesModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production, // Restrict extension to log-only mode
          connectInZone: true,
        })
      : [],
    SharedModule,
    PhotoModule,
    AppStateModule,
    VersionMismatchModule,
    AuthModule,
    ReportsModule,
    BackgroundRequestModule,
    ReportListModule,
    StoreModule.forFeature(preferencesFeatureKey, preferencesReducer),
    reportDiaryModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const authService = inject(AuthService);
        return () => authService.init();
      },
      multi: true,
      deps: [AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const storageService = inject(StorageService);
        const store = inject(Store);
        return async () => {
          const storage = await storageService.init();
          const state = await fetchState(storage);
          store.dispatch({ type: StorageSyncActions.HYDRATED, payload: state });
        };
      },
      multi: true,
      deps: [StorageService, Store],
    },
    provideHttpClient(withInterceptors([TokenInterceptor])),
    // {
    //   provide: BASE_PATH,
    //   useValue: DUMMY_BASE_PATH,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
