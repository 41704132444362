import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { isNil } from 'rambda';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { hapticSelect, hapticLight } from '@qld-recreational/haptic';
import { ISettingState, selectIsDebugMode } from '../settings/settings.reducer';
import { Observable } from 'rxjs';
import {
  ContentfulEntries,
  getId,
  IContentfulEntry,
  ISideMenu,
  ISideMenuFields,
  ISideMenuItem,
} from '../contentful/contentful';
import { Browser } from '@capacitor/browser';
import { IContentfulState } from '../contentful/contentful.reducer';
import { ContentfulService } from '../contentful/contentful.service';
import { IAuthState, selectEmail } from '../auth/auth.reducer';
import { auth } from '../auth/auth.actions';

@Component({
  selector: 'qld-recreational-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  public sideMenu: ISideMenu;
  public isDebugMode$: Observable<boolean>;

  public email$: Observable<string>;

  constructor(
    private cs: ContentfulService,
    private navController: NavController,
    private location: Location,
    private router: Router,
    private menuController: MenuController,
    private settingsStore: Store<ISettingState>,
    private authStore: Store<IAuthState>
  ) {}

  public ngOnInit(): void {
    this.location.onUrlChange(this.onUrlChange);
    this.isDebugMode$ = this.settingsStore.select(selectIsDebugMode);
    this.email$ = this.authStore.select(selectEmail);
  }

  public goToAccount() {
    this.router.navigateByUrl('account');
    this.menuController.close();
  }

  public login() {
    this.navController.navigateForward('/login');
    this.menuController.close();
  }

  private onUrlChange = (url: string) => {
    if (url === '/home') {
      this.initSideMenu();
    }
  };

  private async initSideMenu() {
    this.sideMenu = (
      await this.cs.getEntryById<ISideMenuFields>(ContentfulEntries.SideMenu)
    ).fields;
  }

  public async openExternalLink(link: string) {
    hapticLight();
    await Browser.open({ url: link, presentationStyle: 'popover' });
  }

  public openLink(sideMenu: ISideMenuItem) {
    if (!isNil(sideMenu.externalLink)) {
      this.openExternalLink(sideMenu.externalLink);
      return;
    }
    if (!isNil(sideMenu.internalLink)) {
      this.menuController.close();
      hapticLight();
      this.router.navigateByUrl(sideMenu.internalLink);
      return;
    }
    if (!isNil(sideMenu.internalReference)) {
      this.menuController.close();
      hapticLight();
      this.router.navigateByUrl(
        `reference/${getId(sideMenu.internalReference)}`
      );
      return;
    }
  }

  public goToSettings() {
    hapticSelect();
    this.menuController.close();
    this.router.navigateByUrl('settings');
  }
}
