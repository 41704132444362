<ion-content [forceOverscroll]="false" class="ion-padding">
  <ion-text>
    <p [innerHTML]="message"></p>
  </ion-text>

  <div class="buttons-container">
    <ion-button expand="block" class="main-button" (click)="goToLogin()"
      >Go to Sign in / Sign up</ion-button
    >
    <ion-button class="secondary-button" expand="block" (click)="doItLater()"
      >Do it later</ion-button
    >
  </div>
</ion-content>
