import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  Subscription,
  firstValueFrom,
  from,
  map,
  of,
  switchMap,
  take,
  throwError,
  timer,
} from 'rxjs';
import { Store } from '@ngrx/store';
import {
  ISettingState,
  selectReportingApiUrl,
} from '../settings/settings.reducer';
import { environment } from '../../environments/environment';
import { DefaultService, Preferences } from '@qld-recreational/rec-api';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { isNilOrEmpty, omit } from '@qld-recreational/ramda';
import { IGETReport } from '../report-list/report-list.model';
import {
  BackgroundRequest,
  ReportBackgroundRequest,
} from '../background-request/background-request.model';
import { UploadPhotoService } from '../photo/upload-photo.service';
import { ReportingService } from './reporting.service';
import { SpeciesService } from './species.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends DefaultService implements OnDestroy {
  private reportingBaseUrl$ = this.settingsStore.select(selectReportingApiUrl);

  private baseUrl$$?: Subscription;

  constructor(
    protected httpClient: HttpClient,
    private settingsStore: Store<ISettingState>,
    private uploadPhotoService: UploadPhotoService,
    private reportingService: ReportingService,
    private speciesService: SpeciesService
  ) {
    super(httpClient, undefined, undefined);

    this.baseUrl$$ = this.reportingBaseUrl$.subscribe((url) => {
      this.basePath = url;
    });
  }

  get clientVersion() {
    return environment.version.replace(/\./g, '');
  }

  ngOnDestroy(): void {
    this.baseUrl$$?.unsubscribe();
  }

  public getReports() {
    return this.reportsGet(this.clientVersion, uuid(), 0);
  }

  public loadReports(page = 1): Observable<IGETReport[]> {
    return from(this.reportsGet(this.clientVersion, uuid(), page)).pipe(
      switchMap((response) => {
        if (
          page > 0 &&
          (isNilOrEmpty(response.reports) ||
            response.pageSize !== response.reports?.length)
        ) {
          return of(response.reports || []);
        }

        return this.loadReports(page + 1).pipe(
          map((reports) => [...reports, ...response.reports])
        );
      })
    );
  }

  public async getAllReports() {
    const version = environment.version.replace(/\./g, '');

    const reports = [];

    let page = 1;
    while (true) {
      const response = await firstValueFrom(
        this.reportsGet(version, uuid(), page++)
      );

      reports.push(...(response.reports ?? []));

      if (
        isNilOrEmpty(response.reports) ||
        response.pageSize === response.reports?.length
      ) {
        break;
      }
    }

    return reports;
  }

  private uploadReportPhotos(backgroundRequest: ReportBackgroundRequest) {
    return this.reportingService
      .getSignedUrlsForUpload(backgroundRequest.images?.length || 0)
      .pipe(
        switchMap(({ signedUrls }) =>
          this.uploadPhotoService
            .uploadPhotos(
              backgroundRequest.images?.map((dataUrl) => ({
                dataUrl,
                saved: false,
                format: '',
              })) || [],
              signedUrls
            )
            .pipe(map(() => signedUrls.map((url) => url.split('/')[7])))
        )
      );
  }

  sendBackgroundRequest(backgroundRequest: BackgroundRequest) {
    switch (backgroundRequest.path) {
      case 'reports':
        return this.uploadReportPhotos(backgroundRequest).pipe(
          switchMap((uploadedImages) => {
            const payload = {
              ...backgroundRequest.payload,
              ...(uploadedImages.length > 0
                ? { images: uploadedImages }
                : { images: [] }),
            };
            return this.reportsPost(
              this.clientVersion,
              backgroundRequest.id,
              payload
            );
          })
        );

      case 'claims':
        return from(
          this.speciesService.getSpeciesCaabCode(
            backgroundRequest.payload.speciesId
          )
        ).pipe(
          switchMap((caabCode) =>
            this.claimsPost(this.clientVersion, backgroundRequest.id, {
              ...omit('speciesId', backgroundRequest.payload),
              caabCode,
            })
          )
        );

      case 'not_implemented':
      default:
        return timer(2_000).pipe(
          take(1),
          switchMap(() =>
            throwError(
              () => new Error('Not implemented in sendBackgroundRequest')
            )
          )
        );
    }
  }

  saveUserPreferences(preferences: string) {
    return this.preferencesPost(this.clientVersion, uuid(), {
      settings: preferences,
    });
  }

  getUserPreferences(): Observable<Preferences> {
    return this.preferencesGet(this.clientVersion, uuid());
  }
}
