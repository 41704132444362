import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IReportEntity } from './report-list.model';
import { ClaimPayload } from '../shared/model';

export const ReportListActions = createActionGroup({
  source: 'ReportList',
  events: {
    'Load ReportLists': emptyProps(),
    'Add Report': props<{ report: IReportEntity }>(),
    'Update Report': props<{ oldID: string; newID: string }>(),
    'Load ReportLists Success': props<{ reports: IReportEntity[] }>(),
    'Load ReportLists Failure': props<{ error: unknown }>(),
    'Update Report Claim': props<{ claim: ClaimPayload }>(),
  },
});
