import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentfulService } from './contentful.service';
import { StoreModule } from '@ngrx/store';
import * as fromContentful from './contentful.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContentfulEffects } from './contentful.effects';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { VersionMismatchModule } from '../version-mismatch/version-mismatch.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromContentful.contentfulFeatureKey,
      fromContentful.reducer
    ),
    EffectsModule.forFeature([ContentfulEffects]),
    VersionMismatchModule,
  ],
  providers: [ContentfulService, Insomnia],
})
export class ContentfulModule {}
