import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ContentfulEntries, IAppInfoFields } from '../contentful/contentful';
import { ContentfulService } from '../contentful/contentful.service';
import { compare } from 'compare-versions';

@Injectable()
export class VersionMismatchService {
  constructor(private cf: ContentfulService) {}

  public async getAppInfo() {
    const entry = await this.cf.getEntryById<IAppInfoFields>(
      ContentfulEntries.AppInfo
    );
    return entry;
  }

  public clientIsOlder(version: string): boolean {
    return compare(version, environment.version, '>');
  }

  public clientIsNewer(version: string): boolean {
    return compare(version, environment.version, '<');
  }
}
