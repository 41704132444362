import { Envs } from '../settings/settings';
import { Preferences } from '@capacitor/preferences';
import { AuthResult, ProviderOptions } from '@ionic-enterprise/auth';
import { App } from '@capacitor/app';
import { environment } from '../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { equals } from '@qld-recreational/ramda';

// These need to match the suffixes in the envs-app.json file
export enum AuthTypes {
  Login = 'login',
  Signup = 'signup',
  ChangePassword = 'change-password',
  //
  // The delete account one doesn't matter
  DeleteAccount = 'delete-account',
}

export const ACCESS_TOKEN_KEY = 'APP_AUTH_ACCESS_TOKEN';
export const AUTH_RESPONSE_KEY = 'APP_AUTH_AUTH_RESPONSE';
export const ID_TOKEN_KEY = 'APP_AUTH_ID_TOKEN';
export const REFRESH_TOKEN_KEY = 'APP_AUTH_REFRESH_TOKEN';

export enum AuthenticationError {
  UserDecline = 'USER_CANCELLED',
}

export function storeTokens(authResult: AuthResult) {
  return Promise.all([
    tokenStorageProvider.setAccessToken(authResult.accessToken),
    tokenStorageProvider.setAuthResponse(JSON.stringify(authResult)),
    tokenStorageProvider.setIdToken(authResult.idToken),
    tokenStorageProvider.setRefreshToken(authResult.refreshToken),
  ]);
}

export async function getAuthResponse(): Promise<AuthResult> {
  const result = await tokenStorageProvider.getAuthResponse();
  return JSON.parse(result);
}

export function clearTokens() {
  return tokenStorageProvider.clear();
}

const tokenStorageProvider = {
  clear: async () =>
    [
      ACCESS_TOKEN_KEY,
      AUTH_RESPONSE_KEY,
      ID_TOKEN_KEY,
      REFRESH_TOKEN_KEY,
    ].forEach((key) => Preferences.remove({ key })),
  getAccessToken: async () =>
    (await Preferences.get({ key: ACCESS_TOKEN_KEY })).value,
  getAuthResponse: async () =>
    JSON.parse((await Preferences.get({ key: AUTH_RESPONSE_KEY })).value),
  getIdToken: async () => (await Preferences.get({ key: ID_TOKEN_KEY })).value,
  getRefreshToken: async () =>
    (await Preferences.get({ key: REFRESH_TOKEN_KEY })).value,
  setAccessToken: async (accessToken: string) =>
    Preferences.set({
      key: ACCESS_TOKEN_KEY,
      value: accessToken,
    }),
  setAuthResponse: async (authResponse: string) =>
    Preferences.set({
      key: AUTH_RESPONSE_KEY,
      value: JSON.stringify(authResponse),
    }),
  setRefreshToken: async (refreshToken: string) =>
    Preferences.set({
      key: REFRESH_TOKEN_KEY,
      value: refreshToken,
    }),
  setIdToken: async (idToken: string) =>
    Preferences.set({
      key: ID_TOKEN_KEY,
      value: idToken,
    }),
};

export const getAudienceFromDiscoveryUrl = (discoveryUrl: string) =>
  'https://' + discoveryUrl?.replace('https://', '')?.split('/')?.[0];

export const ionicAuthOptions = async (
  authType: AuthTypes,
  env: Envs,
  isMobile: boolean
): Promise<ProviderOptions> => {
  const discoveryUrl = env[`discoveryUrl-${authType}`];
  const redirectUri = encodeURI(await getRedirectUri(isMobile, authType));

  const clientId = env.authClientId;

  const scopes = [
    'openid',
    'offline_access',
    (env.extraScopes || '').split(' '),
  ];

  const options = {
    audience: getAudienceFromDiscoveryUrl(discoveryUrl),
    clientId,
    discoveryUrl,
    redirectUri,
    scope: scopes.join(' '),
    logoutUrl: redirectUri,
  };

  if (!environment.production) {
    console.log('auth options', options);
  }
  return options;
};

const getRedirectUri = async (isMobile: boolean, authType: AuthTypes) => {
  if (!isMobile) {
    return `${window.location.origin}/login`;
  }

  const appId = (await App.getInfo()).id;

  if (
    equals(authType, AuthTypes.DeleteAccount) &&
    equals(Capacitor.getPlatform(), 'android')
  ) {
    return `${appId}.android://login`;
  } else {
    return `${appId}://login`;
  }
};
