import { Injectable } from '@angular/core';
import { IAppState } from '../../app-state/app-state.reducer';
import { filter, take } from 'rxjs/operators';
import { hapticWarning } from '@qld-recreational/haptic';
import { ModalController } from '@ionic/angular';
import { MemoizedSelector, Store, Action } from '@ngrx/store';

@Injectable()
export class InstructionService {
  constructor(
    private modalController: ModalController,
    private appStateStore: Store<IAppState>
  ) {}

  public async presentInstructionModal({
    selector,
    component,
    cssClass,
    action,
    animated,
    componentProps,
  }: {
    selector: MemoizedSelector<any, any>;
    component: any;
    cssClass: string;
    action: Action<any>;
    animated: boolean;
    componentProps?: Record<string, unknown>;
  }) {
    this.appStateStore
      .select(selector)
      .pipe(
        take(1),
        filter((hasShownInstruction) => !hasShownInstruction)
      )
      .subscribe(async () => {
        const modal = await this.modalController.create({
          component,
          animated,
          cssClass,
          componentProps,
        });
        hapticWarning();
        await modal.present();
        await modal.onDidDismiss();
        this.appStateStore.dispatch(action);
      });
  }
}
