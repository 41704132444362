export const sharedEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyBkp06O602SZJttiOuwPdXiKhtPxYYfa5A',
    authDomain: 'qld-rec-prod-d918e.firebaseapp.com',
    projectId: 'qld-rec-prod-d918e',
    storageBucket: 'qld-rec-prod-d918e.appspot.com',
    messagingSenderId: '482733257304',
    appId: '1:482733257304:web:3da179d44636807040502d',
    measurementId: 'G-1Q8DHRE9S5',
  },
};
