import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BackgroundRequest } from './background-request.model';
import { UpdateStr } from '@ngrx/entity/src/models';

export const BackgroundRequestActions = createActionGroup({
  source: 'BackgroundRequest',
  events: {
    'Attempt Background Request': props<{
      backgroundRequest: BackgroundRequest;
    }>(),
    'Submit Background Request': props<{
      backgroundRequest: BackgroundRequest;
    }>(),
    'Submit Background Request Success':
      props<Pick<BackgroundRequest, 'id' | 'path'>>(),
    'Submit Background Request Failure': props<{
      backgroundRequest: UpdateStr<BackgroundRequest>;
    }>(),
    'No Connection Background Request': emptyProps(),
    'Delete Background Request': props<Pick<BackgroundRequest, 'id'>>(),
    'Add Background Request': props<{
      backgroundRequest: BackgroundRequest;
    }>(),
  },
});
