import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ReportListActions } from './report-list.actions';
import { deleteAccountSuccess, switchUser } from '../auth/auth.actions';
import { clearTestData, switchEnv } from '../settings/settings.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { ViewStatus } from '../shared/ViewStatus';
import { IReportEntity } from './report-list.model';
import { complement, equals, not, pick } from '@qld-recreational/ramda';
import { getReportDate } from './report-list.util';

export const reportListFeatureKey = 'reportList';

const adapter = createEntityAdapter<IReportEntity>({
  selectId: (report) => report.clientRequestID,
  sortComparer: (a, b) => {
    const aDate = getReportDate(a);
    const bDate = getReportDate(b);
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  },
});

const selectors = adapter.getSelectors();

const initialState = adapter.getInitialState({
  viewStatus: ViewStatus.Initial,
});

export const reportListPersistenceKeys = Object.keys(initialState).filter(
  complement(equals('viewStatus'))
);

export type IReportListState = typeof initialState;

export const reducer = createReducer(
  initialState,
  on(ReportListActions.loadReportLists, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(ReportListActions.addReport, (state, action) =>
    adapter.upsertOne(action.report, state)
  ),
  on(ReportListActions.updateReport, (state, action) =>
    adapter.updateOne(
      {
        id: action.oldID,
        changes: {
          clientRequestID: action.newID,
        },
      },
      state
    )
  ),
  on(ReportListActions.updateReportClaim, (state, action) =>
    adapter.map((report) => {
      if (not(equals(report.reportID, action.claim.reportID))) {
        return report;
      }
      return {
        ...report,
        species: report.species.map((species) => {
          if (not(equals(species.speciesId, action.claim.speciesId))) {
            return species;
          }
          return {
            ...species,
            ...pick(
              ['keptIndividualPortion', 'releasedIndividualPortion'],
              action.claim
            ),
          };
        }),
      };
    }, state)
  ),
  on(ReportListActions.loadReportListsSuccess, (state, action) =>
    adapter.upsertMany(action.reports, {
      ...state,
      viewStatus: ViewStatus.Success,
    })
  ),
  on(ReportListActions.loadReportListsFailure, (state, action) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
  })),
  on(
    switchUser,
    switchEnv,
    clearTestData,
    deleteAccountSuccess,
    () => initialState
  )
);

const reportListFeature = createFeature({
  name: reportListFeatureKey,
  reducer,
  extraSelectors: (baseSelectors) => ({
    selectReportList: createSelector(
      baseSelectors.selectReportListState,
      selectors.selectAll
    ),
    selectReport: (clientRequestID: IReportEntity['clientRequestID']) =>
      createSelector(
        baseSelectors.selectEntities,
        (entities) => entities[clientRequestID]
      ),
    selectIsLoading: createSelector(
      baseSelectors.selectViewStatus,
      equals(ViewStatus.Loading)
    ),
  }),
});

export const {
  reducer: reportListReducer,
  selectReportList,
  selectReport,
  selectIsLoading,
  selectViewStatus: selectReportListViewStatus,
} = reportListFeature;
