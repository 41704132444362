import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  reportDiaryFeatureKey,
  reportDiaryReducer,
} from './report-diary.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(reportDiaryFeatureKey, reportDiaryReducer),
  ],
})
export class reportDiaryModule {}
