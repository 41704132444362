import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UploadPhotoService } from '../photo/upload-photo.service';
import { ISettingState, selectApiUrl } from '../settings/settings.reducer';
import { IWhichFishIsThisState } from '../which-fish-is-this/which-fish-is-this.reducer';
import { IGetSignedUrlsResponseBody } from '@qld-recreational/models';
import {
  Observable,
  defaultIfEmpty,
  forkJoin,
  of,
  retry,
  switchMap,
  take,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(
    private http: HttpClient,
    private uploadPhotoService: UploadPhotoService,
    private settingsStore: Store<ISettingState>
  ) {}

  private serverUrl = () =>
    this.settingsStore.select(selectApiUrl).pipe(take(1));

  public getSignedUrlsForUpload(
    length: number
  ): Observable<IGetSignedUrlsResponseBody> {
    if (length === 0) {
      return of({ signedUrls: [] });
    }
    const photoIds = this.uploadPhotoService.generatePhotoIds(length);
    return this.serverUrl().pipe(
      switchMap((apiUrl) =>
        this.http.post<IGetSignedUrlsResponseBody>(
          `${apiUrl}/reporting/signed-urls`,
          {
            photoIds,
          }
        )
      )
    );
  }

  private getSignedUrlForDownload(photoId: string): Observable<string> {
    return this.serverUrl().pipe(
      switchMap((apiUrl) =>
        this.http.get(`${apiUrl}/reporting/signed-url/${photoId}`, {
          responseType: 'text',
        })
      )
    );
  }

  public getImages(photos: string[]) {
    return forkJoin(
      photos.map((photo) => this.getSignedUrlForDownload(photo).pipe(retry(3)))
    ).pipe(defaultIfEmpty([]));
  }
}
