import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './settings.effects';
import { StoreModule } from '@ngrx/store';
import * as fromSettings from '../settings/settings.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSettings.settingsFeatureKey,
      fromSettings.reducer
    ),
    EffectsModule.forFeature([SettingsEffects]),
  ],
})
export class SettingsModule {}
