import { complement, equals, prop } from '@qld-recreational/ramda';
import { ViewStatus } from '../shared/ViewStatus';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  authWithRedirect,
  logout,
  authFailure,
  logoutFailure,
  authSuccess,
  logoutSuccess,
  deleteAccountSuccess,
} from './auth.actions';
import { switchEnv } from '../settings/settings.actions';

export interface IUser {
  id: string;
  email: string;
}

export interface IAuthState {
  error: string;
  viewStatus: ViewStatus;
  user: IUser;
  previouslyLoggedInUserEmail: string;
}

export const initialState: IAuthState = {
  error: undefined,
  viewStatus: ViewStatus.Initial,
  user: undefined,
  previouslyLoggedInUserEmail: undefined,
};

export const authPersistenceKeys = Object.keys(initialState).filter(
  complement(equals('viewStatus'))
);

const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialState,
    on(authWithRedirect, logout, (state) => ({
      ...state,
      viewStatus: ViewStatus.Loading,
    })),
    on(authFailure, logoutFailure, (state, { error }) => ({
      ...state,
      viewStatus: ViewStatus.Failure,
      error,
    })),
    on(authSuccess, (state, { id, email }) => ({
      ...state,
      viewStatus: ViewStatus.Success,
      user: {
        id,
        email,
      },
    })),
    on(deleteAccountSuccess, () => ({
      ...initialState,
      previouslyLoggedInUserEmail: 'deleted user',
    })),
    on(logoutSuccess, switchEnv, (state) => ({
      ...initialState,
      previouslyLoggedInUserEmail: state.user?.id,
    }))
  ),
  extraSelectors: ({ selectViewStatus, selectUser }) => ({
    selectAuthIsLoading: createSelector(
      selectViewStatus,
      equals(ViewStatus.Loading)
    ),
    selectUserID: createSelector(selectUser, prop('id')),
    selectEmail: createSelector(selectUser, prop('email')),
  }),
});

export const {
  name: authFeatureKey,
  reducer,
  selectAuthState,
  selectPreviouslyLoggedInUserEmail,
  selectViewStatus,
  selectUserID,
  selectEmail,
  selectAuthIsLoading,
} = authFeature;
