import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  authFailure,
  authSuccess,
  authWithRedirect,
  logout,
  logoutFailure,
  logoutSuccess,
  switchUser,
  authInit,
  auth,
  deleteAccountSuccess,
  deleteAccount,
  deleteAccountFail,
} from './auth.actions';
import {
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from './auth.service';
import { ToastService } from '@qld-recreational/toast';
import { equals, not } from 'rambda';
import { AuthenticationError } from './auth';
import { MESSAGES } from '../messages';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';
import {
  IAuthState,
  selectAuthIsLoading,
  selectPreviouslyLoggedInUserEmail,
  selectUserID,
} from './auth.reducer';
import { and, isNilOrEmpty } from '@qld-recreational/ramda';
import { switchEnv } from '../settings/settings.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private toastService: ToastService,
    private authStore: Store<IAuthState>,
    private router: Router
  ) {}

  public loginWithoutRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth),
      map(({ authType }) => authWithRedirect({ authType }))
    )
  );

  public login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authWithRedirect),
      switchMap(({ redirectUrl, authType }) =>
        this.authService.auth(authType).pipe(
          switchMap(() => combineLatest([this.authService.getUserDetails()])),
          map(([details]) =>
            authSuccess({
              ...details,
              redirectUrl,
            })
          ),
          tap((_) =>
            this.toastService.presentSuccessToast(
              MESSAGES.authSuccess,
              'half-width-toast'
            )
          ),
          catchError((error) => {
            console.log(error);
            if (!equals(error.message, AuthenticationError.UserDecline)) {
              this.toastService.presentFailureToast(MESSAGES.authFailure);
            }
            return of(authFailure({ error }));
          })
        )
      )
    );
  });

  public authFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authFailure),
        tap(() => this.authService.clear())
      ),
    {
      dispatch: false,
    }
  );

  public logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      switchMap(() =>
        this.authService.logout().pipe(
          map(logoutSuccess),
          catchError((error) => of(logoutFailure({ error })))
        )
      )
    );
  });

  public loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSuccess),
        withLatestFrom(
          this.authStore.select(selectAuthIsLoading),
          this.authStore.select(selectUserID)
        ),
        filter(
          ([_, isLoading, email]) => not(isLoading) && not(isNilOrEmpty(email))
        ),
        tap(([{ redirectUrl }]) => {
          if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  public logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccess, logoutFailure, deleteAccountSuccess, switchEnv),
        tap(() => Preferences.set({ key: 'loggedIn', value: 'false' })),
        tap(() => this.router.navigateByUrl('home', { replaceUrl: true })),
        tap(() => console.log('logout success'))
      ),
    {
      dispatch: false,
    }
  );

  public deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAccount),
      switchMap(() =>
        this.authService.deleteAccount().pipe(
          map(() => deleteAccountSuccess()),
          catchError((error) => of(deleteAccountFail()))
        )
      )
    )
  );

  public deleteAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteAccountSuccess),
        tap(() => this.authService.clear()),
        tap(() =>
          this.toastService.presentSuccessToast(
            MESSAGES.deleteAccountSuccessMessage
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  public deleteAccountFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteAccountFail),
        tap(() =>
          this.toastService.presentFailureToast(
            MESSAGES.deleteAccountFailMessage
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  public switchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSuccess),
      withLatestFrom(this.authStore.select(selectPreviouslyLoggedInUserEmail)),
      map(([{ email }, previouslyLoggedInUserEmail]) =>
        and(
          !isNilOrEmpty(previouslyLoggedInUserEmail),
          !equals(email, previouslyLoggedInUserEmail)
        )
      ),
      filter((isUserChanged) => isUserChanged),
      map(switchUser)
    )
  );
}
