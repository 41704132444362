import { Injectable } from '@angular/core';
import { ContentfulService } from '../contentful/contentful.service';
import { StorageService } from '@qld-recreational/storage';
import {
  ContentfulEntries,
  ISpeciesFields,
  ISpeciesGroupFields,
  ISpeciesSummaryFields,
} from '../contentful/contentful';
import { isNilOrEmpty, pick } from '@qld-recreational/ramda';
import { ISpeciesDisplayInfo } from './model';
import { ReportSpecies } from '@qld-recreational/rec-api';
import { IReportEntity } from '../report-list/report-list.model';

@Injectable({
  providedIn: 'root',
})
export class SpeciesService {
  constructor(private cs: ContentfulService, private storage: StorageService) {}

  public async getAllSpeciesIds() {
    const speciesGroups = await this.cs.getEntryById<ISpeciesSummaryFields>(
      ContentfulEntries.SpeciesSummary
    );

    if (isNilOrEmpty(speciesGroups)) {
      return [];
    }

    const species = await Promise.all(
      speciesGroups.fields.speciesGroups.map(async (group) => {
        const speciesGroup = await this.cs.getEntryById<ISpeciesGroupFields>(
          group.sys.id
        );

        if (isNilOrEmpty(speciesGroup)) {
          return [];
        }

        return speciesGroup.fields.speciesList.map((species) => species.sys.id);
      })
    );

    return species.flat();
  }

  private async getSpeciesPhotoStorage(photoId: string): Promise<string> {
    if (isNilOrEmpty(photoId)) {
      return '';
    }
    try {
      return this.storage.get(photoId);
    } catch (e) {
      return '';
    }
  }

  public async getSpeciesName(speciesId: string) {
    const species = await this.cs.getEntryById<ISpeciesFields>(speciesId);

    if (isNilOrEmpty(species)) {
      return null;
    }

    return species.fields.commonName;
  }

  public async getSpeciesDisplayInfo(
    speciesId: string
  ): Promise<ISpeciesDisplayInfo> {
    const species = await this.cs.getEntryById<ISpeciesFields>(speciesId);

    const photoId = species?.fields?.speciesPhotos?.[0]?.sys?.id;
    return {
      commonName: species?.fields?.commonName,
      imageSrc: photoId ? await this.getSpeciesPhotoStorage(photoId) : null,
    };
  }

  public async getSpeciesCaabCode(speciesId: string) {
    const species = await this.cs.getEntryById<ISpeciesFields>(speciesId);

    if (isNilOrEmpty(species)) {
      return null;
    }

    return species.fields.caabCode;
  }

  public async transformSpeciesList(
    species: ReportSpecies[]
  ): Promise<IReportEntity['species']> {
    return Promise.all(
      (species ?? []).map(async (species) => ({
        ...pick(
          [
            'numberKept',
            'numberReleased',
            'keptIndividualPortion',
            'releasedIndividualPortion',
          ],
          species
        ),
        speciesId: await this.findSpeciesId(species.caabCode),
      }))
    );
  }

  private async findSpeciesId(caabCode: number) {
    const speciesGroups = await this.cs.getEntryById<ISpeciesSummaryFields>(
      ContentfulEntries.SpeciesSummary
    );

    if (isNilOrEmpty(speciesGroups)) {
      return '';
    }

    for (const group of speciesGroups.fields.speciesGroups) {
      const speciesGroup = await this.cs.getEntryById<ISpeciesGroupFields>(
        group.sys.id
      );

      if (isNilOrEmpty(speciesGroup)) {
        continue;
      }

      for (const species of speciesGroup.fields.speciesList) {
        if (species.fields.caabCode === caabCode) {
          return species.sys.id;
        }
      }
    }
  }
}
