import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectReportList,
  selectReportListViewStatus,
} from './report-list.reducer';
import { map, of, skip, startWith, switchMap } from 'rxjs';
import { IGETReport, IReportEntity } from './report-list.model';
import { ReportListActions } from './report-list.actions';
import { AuthService } from '../auth/auth.service';
import { SpeciesService } from '../shared/species.service';
import { equals, prop } from '@qld-recreational/ramda';
import { getReportDate } from './report-list.util';
import { ViewStatus } from '../shared/ViewStatus';
import { EnvironmentalDataService } from '../shared/environmental-data.service';

@Injectable({
  providedIn: 'root',
})
export class ReportListService {
  private fullReportList$ = this.store
    .select(selectReportList)
    .pipe(startWith([] as IReportEntity[]));

  constructor(
    private store: Store,
    private authService: AuthService,
    private speciesService: SpeciesService,
    private environmentalDataService: EnvironmentalDataService
  ) {}

  get reportList$() {
    return this.authService.isLoggedIn$.pipe(
      switchMap((isLoggedIn) =>
        isLoggedIn ? this.fullReportList$ : of([] as IReportEntity[])
      )
    );
  }

  private readonly NUMBER_OF_TRIPS_ON_HOME = 3;
  public lastLastNReports$ = this.reportList$.pipe(
    map((reportList) => reportList.slice(0, this.NUMBER_OF_TRIPS_ON_HOME)),
    map((reportList) =>
      reportList.map((report) => ({
        name: report.tripName || 'Fishing trip',
        date: getReportDate(report),
      }))
    )
  );

  public uniqueSpecies$ = this.reportList$.pipe(
    skip(1),
    map(
      (reportList) =>
        new Set(
          reportList.flatMap((report) => report.species.map(prop('speciesId')))
        )
    )
  );

  public reportsLoading$ = this.store
    .select(selectReportListViewStatus)
    .pipe(map(equals(ViewStatus.Loading)));

  public loadReportList() {
    this.store.dispatch(ReportListActions.loadReportLists());
  }

  public async transformReport(report: IGETReport): Promise<IReportEntity> {
    try {
      const reportEntity: IReportEntity = {
        ...report,
        species: await this.speciesService.transformSpeciesList(
          report.species ?? []
        ),
        weather: this.environmentalDataService.transformWeatherStation(report),
        submitting: false,
      };

      return reportEntity;
    } catch (e) {
      console.error('Error transforming report', e, report);

      return {
        ...report,
        species: [],
        weather: {
          station: 'XX',
          weather: {},
          tide: {},
        },
        submitting: false,
        success: false,
      };
    }
  }
}
