// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { sharedEnv } from './environment.shared';

export const environment = {
  ...sharedEnv,
  production: false,
  version: '2.1.0',
  buildNumber: '138812',
  targetEnv: 'uat',
  contentfulContext: 'staging',
  contentfulSpace: 'r9p6j404vvxj',
  contentfulProductionAccessToken:
    'YIQiff5e0o9rcnhCsTT4SGBDSBHNMrBKbHZNYVeQ-Bk',
  contentfulStagingAccessToken: 'BAMpIe91nVvL6WTuHVuv6ryscNWTXuVWy39bel2fJnQ',
  oneSignalAppId: 'e8509e42-b946-48a6-91cb-4bbaaa5f73ce',
  submitInterval: 10_000,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
