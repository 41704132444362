import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadPhotoService } from './upload-photo.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [UploadPhotoService],
})
export class PhotoModule {}
