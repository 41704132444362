import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromAppState from './app-state.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromAppState.appStateFeatureKey,
      fromAppState.reducer
    ),
  ],
})
export class AppStateModule {}
