import { equals, includes } from '@qld-recreational/ramda';
import moment from 'moment';
import { ReportDiaryGraphRange } from '../report-diary/report-diary.model';
import { isNil } from 'rambda';

export enum DepredationFilterType {
  All = 'all trips',
  Depredation = 'depredation',
  NoDepredation = 'no depredation',
}

export enum TimeFilterType {
  All = 1,
  Last,
  Between,
  Since,
}

export interface TimeFilterAll {
  type: TimeFilterType.All;
}

export interface TimeFilterBetween {
  type: TimeFilterType.Between;
  from: string;
  to: string;
}

export interface TimeFilterSince {
  type: TimeFilterType.Since;
  from: string;
}

export interface TimeFilterLast {
  type: TimeFilterType.Last;
  count: number;
  period: 'days' | 'weeks' | 'months' | 'years';
}

export type TimeFilter =
  | TimeFilterAll
  | TimeFilterBetween
  | TimeFilterSince
  | TimeFilterLast;

export enum ReportDiaryCatchTypes {
  Individual = 'Individual',
  Boat = 'Boat',
}

export type CatchTypeFilter = ReportDiaryCatchTypes;

export type SpeciesFilter = Array<{ speciesId: string; name: string }>;

export enum TimeFilterSummary {
  Last1Month = 'last 1 month',
  Last6Months = 'last 6 months',
  Last12Months = 'last 12 months',
  All = 'All',
}

export const timeFilterSummaryMap: Record<TimeFilterSummary, TimeFilter> = {
  [TimeFilterSummary.Last1Month]: {
    type: TimeFilterType.Last,
    count: 1,
    period: 'months',
  },
  [TimeFilterSummary.Last6Months]: {
    type: TimeFilterType.Last,
    count: 6,
    period: 'months',
  },
  [TimeFilterSummary.Last12Months]: {
    type: TimeFilterType.Last,
    count: 12,
    period: 'months',
  },
  [TimeFilterSummary.All]: {
    type: TimeFilterType.All,
  },
};

export function findFilterFromSummaryMap(
  filter: TimeFilter
): TimeFilterSummary | undefined {
  return Object.keys(timeFilterSummaryMap).find((key) =>
    equals(timeFilterSummaryMap[key], filter)
  ) as TimeFilterSummary | undefined;
}

export const DEFAULT_TIME_FILTER: TimeFilter =
  timeFilterSummaryMap[TimeFilterSummary.Last12Months];

export const DEFAULT_SPECIES_FILTER: SpeciesFilter = [];

export const DEFAULT_DEPREDATION_FILTER = DepredationFilterType.All;

export function timeFilterRange(filter: TimeFilter): {
  start: moment.Moment;
  end: moment.Moment;
} {
  switch (filter.type) {
    case TimeFilterType.All:
      return {
        start: moment(new Date(0)),
        end: moment(),
      };
    case TimeFilterType.Last:
      return {
        start: moment().subtract(filter.count, filter.period),
        end: moment(),
      };
    case TimeFilterType.Between:
      const start = moment(filter.from).isSameOrBefore(filter.to)
        ? moment(filter.from)
        : moment(filter.to);
      const end = moment(filter.from).isSameOrBefore(filter.to)
        ? moment(filter.to)
        : moment(filter.from);
      return { start, end };
    case TimeFilterType.Since:
      return {
        start: moment(filter.from),
        end: moment(),
      };
  }
}

export function timeFilterMaxAllowedGraphRange(
  filter: TimeFilter
): ReportDiaryGraphRange {
  const range = timeFilterRange(filter);

  const diff = range.end.diff(range.start, 'months');

  return diff < 1
    ? ReportDiaryGraphRange.W
    : diff < 3
    ? ReportDiaryGraphRange.M
    : diff < 12
    ? ReportDiaryGraphRange.Q
    : ReportDiaryGraphRange.Y;
}

export function searchTimeFilter(date: Date, filter: TimeFilter) {
  const dateSelected = moment(date);

  const { start, end } = timeFilterRange(filter);

  return dateSelected.isBetween(start, end, 'day', '[]');
}

export type Filter = {
  timeFilter: TimeFilter;
  speciesFilter?: SpeciesFilter;
  catchTypeFilter?: CatchTypeFilter;
  depredationFilter?: DepredationFilterType;
};

function isTimeFilter(value: unknown): value is TimeFilter {
  const filter = value as TimeFilter;
  return (
    filter &&
    filter.type &&
    (equals(filter.type, TimeFilterType.All) ||
      equals(filter.type, TimeFilterType.Last) ||
      equals(filter.type, TimeFilterType.Between) ||
      equals(filter.type, TimeFilterType.Since))
  );
}

function isSpeciesFilter(value: unknown): value is SpeciesFilter {
  const filter = value as SpeciesFilter;
  return (
    Array.isArray(filter) &&
    filter.every((species) => species.speciesId && species.name)
  );
}

function isDepredationFilter(value: unknown): value is DepredationFilterType {
  const filter = value as DepredationFilterType;
  return includes(filter, [
    DepredationFilterType.All,
    DepredationFilterType.Depredation,
    DepredationFilterType.NoDepredation,
  ]);
}

export function isFilter(value: unknown): value is Filter {
  const filter = value as Filter;
  return (
    filter &&
    filter.timeFilter &&
    (isNil(filter.depredationFilter) ||
      isDepredationFilter(filter.depredationFilter)) &&
    isTimeFilter(filter.timeFilter) &&
    (isNil(filter.speciesFilter) || isSpeciesFilter(filter.speciesFilter))
  );
}
