import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  clearNextSyncToken,
  hasAcceptedDisclaimer,
  hasOnBoarded,
  hasShownAboutMyFishToast,
  hasShownCanIFishHereInstruction,
  hasShownContactFisheriesFormToast,
  hasShownHomePageReportingInstruction,
  hasShownReportDiaryInstruction,
  hasShownReportHistoryInstruction,
  hasShownReportPageInstruction,
  hasShownSpeciesDetailInstruction,
  hasShownSpeciesListInstruction,
  hasShownSwipeToDismissInstruction,
  hasShownWhichFishIsThisInstruction,
  nextSyncTokenUpdate,
} from './app-state.actions';
import { clearTestData } from '../settings/settings.actions';
import { omit } from '@qld-recreational/ramda';

export const appStateFeatureKey = 'appState';

export interface IAppState {
  hasAcceptedDisclaimer: boolean;
  hasOnBoarded: boolean;
  nextSyncToken: string;
  hasShownSpeciesDetailInstruction: boolean;
  hasShownSpeciesListInstruction: boolean;
  hasShownContactFisheriesFormToast: boolean;
  hasShownAboutMyFishToast: boolean;
  hasShownWhichFishIsThisInstruction: boolean;
  hasShownCanIFishHereInstruction: boolean;
  hasShownSwipeToDismissInstruction: boolean;
  hasShownReportHistoryInstruction: boolean;
  hasShownReportPageInstruction: boolean;
  hasShownReportDiaryInstruction: boolean;
  hasShownHomePageReportingInstruction: boolean;
}

export const initialState: IAppState = {
  hasAcceptedDisclaimer: false,
  hasOnBoarded: false,
  nextSyncToken: undefined,
  hasShownSpeciesDetailInstruction: false,
  hasShownSpeciesListInstruction: false,
  hasShownContactFisheriesFormToast: false,
  hasShownAboutMyFishToast: false,
  hasShownWhichFishIsThisInstruction: false,
  hasShownCanIFishHereInstruction: false,
  hasShownSwipeToDismissInstruction: false,
  hasShownReportHistoryInstruction: false,
  hasShownReportPageInstruction: false,
  hasShownReportDiaryInstruction: false,
  hasShownHomePageReportingInstruction: false,
};

export const reducer = createReducer(
  initialState,
  on(hasAcceptedDisclaimer, (state) => ({
    ...state,
    hasAcceptedDisclaimer: true,
  })),
  on(hasOnBoarded, (state) => ({ ...state, hasOnBoarded: true })),
  on(hasShownSpeciesDetailInstruction, (state) => ({
    ...state,
    hasShownSpeciesDetailInstruction: true,
  })),
  on(hasShownSpeciesListInstruction, (state) => ({
    ...state,
    hasShownSpeciesListInstruction: true,
  })),
  on(hasShownContactFisheriesFormToast, (state) => ({
    ...state,
    hasShownContactFisheriesFormToast: true,
  })),
  on(hasShownAboutMyFishToast, (state) => ({
    ...state,
    hasShownAboutMyFishToast: true,
  })),
  on(hasShownWhichFishIsThisInstruction, (state) => ({
    ...state,
    hasShownWhichFishIsThisInstruction: true,
  })),
  on(hasShownCanIFishHereInstruction, (state) => ({
    ...state,
    hasShownCanIFishHereInstruction: true,
  })),
  on(hasShownSwipeToDismissInstruction, (state) => ({
    ...state,
    hasShownSwipeToDismissInstruction: true,
  })),
  on(hasShownReportHistoryInstruction, (state) => ({
    ...state,
    hasShownReportHistoryInstruction: true,
  })),
  on(hasShownReportPageInstruction, (state) => ({
    ...state,
    hasShownReportPageInstruction: true,
  })),
  on(hasShownReportDiaryInstruction, (state) => ({
    ...state,
    hasShownReportDiaryInstruction: true,
  })),
  on(hasShownHomePageReportingInstruction, (state) => ({
    ...state,
    hasShownHomePageReportingInstruction: true,
  })),
  on(clearTestData, (state) => ({
    ...state,
    ...omit(
      ['hasAcceptedDisclaimer', 'hasOnBoarded', 'nextSyncToken'],
      initialState
    ),
  })),
  on(nextSyncTokenUpdate, (state, action) => ({
    ...state,
    nextSyncToken: action.nextSyncToken,
  })),
  on(clearNextSyncToken, (state) => ({ ...state, nextSyncToken: undefined }))
);

export const selectAppState =
  createFeatureSelector<IAppState>(appStateFeatureKey);

export const selectHasAcceptedDisclaimer = createSelector(
  selectAppState,
  (state) => state.hasAcceptedDisclaimer
);

export const selectHasOnBoarded = createSelector(
  selectAppState,
  (state) => state.hasOnBoarded
);

export const selectNextSyncToken = createSelector(
  selectAppState,
  (state) => state.nextSyncToken
);

export const selectHasShownSpeciesDetailInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownSpeciesDetailInstruction
);

export const selectHasShownSpeciesListInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownSpeciesListInstruction
);

export const selectHasShownContactFisheriesFormToast = createSelector(
  selectAppState,
  (state) => state.hasShownContactFisheriesFormToast
);

export const selectHasShownAboutMyFishToast = createSelector(
  selectAppState,
  (state) => state.hasShownAboutMyFishToast
);

export const selectHasShownWhichFishIsThisInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownWhichFishIsThisInstruction
);

export const selectHasShownCanIFishHereInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownCanIFishHereInstruction
);

export const selectHasShownSwipeToDismissInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownSwipeToDismissInstruction
);

export const selectHasShownReportHistoryInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownReportHistoryInstruction
);

export const selectHasShownReportPageInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownReportPageInstruction
);

export const selectHasShownReportDiaryInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownReportDiaryInstruction
);

export const selectHasShownHomePageReportingInstruction = createSelector(
  selectAppState,
  (state) => state.hasShownHomePageReportingInstruction
);
