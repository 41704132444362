import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { equals } from '@qld-recreational/ramda';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectTargetEnv } from '../settings/settings.reducer';
import { GAEvent } from './google-analytics.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private store: Store) {}

  public async initGoogleAnalytics() {
    if (equals(Capacitor.getPlatform(), 'web')) {
      // Native ones get configured and initialised in the native layer
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
    await FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  }

  public async logPageChange(page: string, ctx?: Record<string, unknown>) {
    return this.logEvent(GAEvent.PageView, {
      page,
      ...ctx,
    });
  }

  public async logFilterChanged(pageContext: string) {
    return this.logEvent(GAEvent.FilterChanged, {
      pageContext,
    });
  }

  private async logEvent(event: GAEvent, params?: Record<string, unknown>) {
    const env = window.location.hostname.includes('localhost')
      ? 'localhost'
      : await firstValueFrom(this.store.select(selectTargetEnv));

    const baseParams = {
      platform: Capacitor.getPlatform(),
      version: environment.version,
      buildNumber: environment.buildNumber,
      env,
    };

    await FirebaseAnalytics.logEvent({
      name: event,
      params: {
        ...baseParams,
        ...params,
      },
    });
  }
}
