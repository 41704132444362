import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import {
  equals,
  includes,
  isNil,
  isNilOrEmpty,
  not,
} from '@qld-recreational/ramda';
import {
  hapticLight,
  hapticSelect,
  hapticWarning,
} from '@qld-recreational/haptic';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private openNativeSettings: OpenNativeSettings
  ) {}

  public async presentDoubleActionAlert(
    header: string,
    message: string,
    yesHandler: () => void,
    yesText: string = 'Ok',
    noHandler?: () => void,
    runNoHandlerOnDismiss = false
  ) {
    hapticLight();
    const alert = await this.alertController.create({
      cssClass: 'qld-alert',
      header,
      message,
      buttons: [
        {
          text: 'Cancel',
          ...(isNil(noHandler) ? { role: 'cancel' } : { handler: noHandler }),
          cssClass: 'danger-alert-button',
        },
        {
          text: yesText,
          handler: yesHandler,
        },
      ],
    });

    if (not(isNil(noHandler))) {
      alert.onWillDismiss().then(({ role }) => {
        if (
          (isNilOrEmpty(role) && runNoHandlerOnDismiss) ||
          equals(role, 'backdrop')
        ) {
          noHandler();
        }
      });
    }

    return alert.present();
  }

  public async presentSingleActionAlert(
    header: string,
    message: string,
    text: string = 'Ok'
  ) {
    hapticLight();
    const alert = await this.alertController.create({
      cssClass: 'qld-alert',
      header,
      message,
      buttons: [
        {
          text,
          role: 'cancel',
        },
      ],
    });
    return alert.present();
  }

  public async presentStartManualTrackingAlert(
    pcfl: string,
    boatmark: string,
    successHandler: () => void
  ) {
    hapticWarning();
    return this.presentDoubleActionAlert(
      'Manual reporting',
      `You are manually reporting for ${pcfl}, ${boatmark}`,
      successHandler,
      'Confirm'
    );
  }

  public async presentLocationPermissionDeniedAlert(
    cancelHandler?: () => void
  ) {
    hapticWarning();
    const alert = await this.alertController.create({
      cssClass: 'qld-alert',
      header: 'User denied access to location',
      message: 'Please change permission in Settings',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'danger-alert-button',
          ...(cancelHandler ? { handler: cancelHandler } : { role: 'cancel' }),
        },
        {
          text: 'Settings',
          handler: () => this.openNativeSettings.open('application_details'),
        },
      ],
    });
    return alert.present();
  }

  public async presentPermissionDeniedAlert(errorMessage: string) {
    enum CameraErrorMessage {
      NoImagePicked = 'No image picked',
      Cancel = 'User cancelled photos app',
      CameraDenied = 'User denied access to camera',
      PhotosDenied = 'User denied access to photos',
    }

    const cameraErrorAlert = async (errorMsg: string) => {
      const isInPermissionDeniedCategory = includes(errorMsg, [
        CameraErrorMessage.PhotosDenied,
        CameraErrorMessage.CameraDenied,
      ]);
      const buttons = isInPermissionDeniedCategory
        ? [
            {
              text: 'Ok',
              role: 'cancel',
              cssClass: 'danger-alert-button',
            },
            {
              text: 'Settings',
              handler: () =>
                this.openNativeSettings.open('application_details'),
            },
          ]
        : [
            {
              text: 'Ok',
              role: 'cancel',
            },
          ];
      return await this.alertController.create({
        cssClass: 'qld-alert',
        header: isInPermissionDeniedCategory
          ? errorMessage
          : 'Unknown error happens, please try again later',
        message: isInPermissionDeniedCategory
          ? 'Please change permission in Settings'
          : '',
        buttons,
      });
    };
    if (
      includes(errorMessage, [
        CameraErrorMessage.Cancel,
        CameraErrorMessage.NoImagePicked,
      ])
    ) {
      return;
    }
    const alert = await cameraErrorAlert(errorMessage);
    await alert.present();
  }

  public async presentDeleteAlert(
    header: string,
    message: string,
    deleteHandler: (index?: number) => void,
    index?: number
  ) {
    hapticSelect();
    const alert = await this.alertController.create({
      cssClass: 'qld-alert',
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          handler: () => deleteHandler(index),
          cssClass: 'danger-alert-button',
        },
      ],
    });

    hapticSelect();
    await alert.present();
  }
}
