import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import {
  createClient,
  Entry,
  Asset,
  ContentfulClientApi,
  EntrySkeletonType,
  FieldsType,
} from 'contentful';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { isEmpty, isNil, isNilOrEmpty } from '@qld-recreational/ramda';
import { Store } from '@ngrx/store';
import { IContentfulState } from './contentful.reducer';
import {
  contentfulApis,
  ContentfulEntryCollection,
  getAssetFile,
  getId,
  IContentfulEntry,
  ISyncCollection,
} from './contentful';
import {
  ISettingState,
  selectContentfulContext,
} from '../settings/settings.reducer';
import { IAppState, selectNextSyncToken } from '../app-state/app-state.reducer';
import {
  clearNextSyncToken,
  nextSyncTokenUpdate,
} from '../app-state/app-state.actions';
import {
  assetsDownloaded,
  updateAssetsCached,
  updateAssetsDownloaded,
  updateAssetsToCache,
  updateEntriesCached,
  updateEntriesToCache,
} from './contentful.actions';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { StorageService } from '@qld-recreational/storage';
import { AlertService } from '@qld-recreational/alert';
import { MESSAGES } from '../messages';

type ContentfulClient = ContentfulClientApi<undefined>;

interface ICacheAsset {
  blob: Blob;
  type: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private client$: Observable<ContentfulClient>;

  constructor(
    private http: HttpClient,
    private contentfulStore: Store<IContentfulState>,
    private settingsStore: Store<ISettingState>,
    private appStateStore: Store<IAppState>,
    private storage: StorageService,
    private insomnia: Insomnia,
    private alertService: AlertService
  ) {
    this.client$ = this.initClient();
  }

  private initClient(): Observable<ContentfulClient> {
    return this.settingsStore.select(selectContentfulContext).pipe(
      map((contentfulContext) => contentfulApis[contentfulContext]),
      map(({ accessToken, host, space }) =>
        createClient({ accessToken, host, space })
      )
    );
  }

  public preventScreenSleep() {
    this.insomnia.keepAwake();
  }

  public allowScreenSleep() {
    this.insomnia.allowSleepAgain();
  }

  public async presentNotificationIfHasChanges(
    syncCollection: ISyncCollection,
    override: boolean
  ) {
    if (
      override ||
      (isNilOrEmpty(syncCollection.assets) &&
        isNilOrEmpty(syncCollection.entries))
    ) {
      return Promise.resolve(undefined);
    }

    return new Promise((resolve, reject) => {
      this.alertService.presentDoubleActionAlert(
        'New content!',
        MESSAGES.contentfulNewContentMessage,
        () => resolve(undefined),
        'Yes',
        reject,
        true
      );
    });
  }

  public checkRemoteUpdate(): Observable<ISyncCollection> {
    const fetchAllEntriesIfNeedToUpdate = (
      client: ContentfulClient,
      syncCollection: ISyncCollection
    ) =>
      !isEmpty(syncCollection.entries)
        ? from(client.getEntries({ limit: 1000 })).pipe(
            map(
              (entries: ContentfulEntryCollection<EntrySkeletonType<{}>>) => ({
                ...syncCollection,
                entries: JSON.parse(entries.stringifySafe()).items,
              })
            )
          )
        : of(syncCollection);

    const updateEntriesAssetsToDownload = (syncCollection: ISyncCollection) => {
      const entriesToCache = syncCollection.entries.length;
      const assetsToCache = syncCollection.assets.length;
      this.contentfulStore.dispatch(updateEntriesToCache({ entriesToCache }));
      this.contentfulStore.dispatch(updateAssetsToCache({ assetsToCache }));
    };

    const sync = (
      client: ContentfulClient,
      nextSyncToken: string
    ): Observable<ISyncCollection> =>
      from(
        client.withoutLinkResolution.sync({
          ...(isNil(nextSyncToken) ? { initial: true } : { nextSyncToken }),
        })
      ).pipe(
        map((syncCollection: ISyncCollection) => ({
          ...JSON.parse(syncCollection.stringifySafe()),
        })),
        switchMap((syncCollection) =>
          fetchAllEntriesIfNeedToUpdate(client, syncCollection)
        ),
        tap(updateEntriesAssetsToDownload)
      );

    return combineLatest([
      this.client$,
      this.appStateStore.select(selectNextSyncToken).pipe(take(1)),
    ]).pipe(
      switchMap(([client, nextSyncToken]) => sync(client, nextSyncToken))
    );
  }

  public syncEntries(
    syncCollection: ISyncCollection
  ): Observable<ISyncCollection> {
    const store = (entries: Array<Entry<any>>): Observable<Array<any>> =>
      combineLatest(
        entries.map((entry) =>
          this.storage
            .set(getId(entry), JSON.stringify(entry))
            .then(() => this.contentfulStore.dispatch(updateEntriesCached()))
        )
      );

    const sync = (): Observable<ISyncCollection> => {
      const { entries, deletedEntries } = syncCollection;
      if (isEmpty(entries) && isEmpty(deletedEntries)) {
        return of(syncCollection);
      }
      const updateEntries = store(entries);
      const deleteEntries = deletedEntries.map((entry) =>
        this.storage.remove(getId(entry))
      );

      return combineLatest([
        updateEntries,
        from(Promise.allSettled(deleteEntries)),
      ]).pipe(map(() => syncCollection));
    };

    return sync();
  }

  public syncAssets(syncCollection: ISyncCollection): Observable<string> {
    const { assets, deletedAssets } = syncCollection;
    if (isEmpty(assets) && isEmpty(deletedAssets)) {
      return of(syncCollection.nextSyncToken);
    }
    const updateAssets = this.fetchAssets(assets).pipe(
      tap(() => this.contentfulStore.dispatch(assetsDownloaded())),
      switchMap((assetsToCache) => this.cacheAssets(assetsToCache))
    );
    const deleteAssets = deletedAssets.map((asset) =>
      from(this.storage.remove(getId(asset)))
    );
    return combineLatest([
      updateAssets,
      from(Promise.allSettled(deleteAssets)),
    ]).pipe(map(() => syncCollection.nextSyncToken));
  }

  private fetchAssets(assets: Array<Asset>): Observable<Array<ICacheAsset>> {
    const assetsToFetch = assets
      .filter((asset) => !isNil(getAssetFile(asset)?.url))
      .map((asset) => this.constructAssetUrl(asset));
    return combineLatest(
      assetsToFetch.map((asset) =>
        this.http.get(asset.url, { responseType: 'blob' }).pipe(
          map((blob) => ({ blob, type: asset.type, id: asset.id })),
          tap(() => this.contentfulStore.dispatch(updateAssetsDownloaded()))
        )
      )
    ) as Observable<Array<ICacheAsset>>;
  }

  private cacheAssets(assets: Array<ICacheAsset>) {
    const fileReaderPromise = (blob: Blob, type: string) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        type.includes('image')
          ? reader.readAsDataURL(blob)
          : reader.readAsText(blob);
      });
    return combineLatest(
      assets.map(async (asset) => {
        const result = await fileReaderPromise(asset.blob, asset.type);
        return this.storage
          .set(asset.id, result as string)
          .then(() => this.contentfulStore.dispatch(updateAssetsCached()));
      })
    );
  }

  public getEntryById<T = FieldsType>(
    entryId: string
  ): Promise<IContentfulEntry<T>> {
    return this.storage.get(entryId).then((value) => JSON.parse(value));
  }

  private constructAssetUrl(asset: Asset): {
    id: string;
    url: string;
    type: string;
  } {
    return {
      id: getId(asset),
      type: getAssetFile(asset).contentType,
      url: `https:${getAssetFile(asset).url}?w=${window.innerWidth}`,
    };
  }

  public clearCache(): Observable<void> {
    return this.initClient().pipe(
      switchMap(() => this.storage.clear()),
      tap(() => this.appStateStore.dispatch(clearNextSyncToken()))
    );
  }

  public updateNextToken(nextSyncToken: string) {
    this.appStateStore.dispatch(nextSyncTokenUpdate({ nextSyncToken }));
  }
}
