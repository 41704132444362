import { createAction, props } from '@ngrx/store';
import { EnvMap, TargetEnv } from './settings';
import { ContentfulContext } from '../contentful/contentful';

export const clearTestData = createAction('[Settings] Clear Test Data');

export const loadEnvs = createAction(
  '[Settings] Load ENVs',
  props<{ env: EnvMap }>()
);

export const enableDebugMode = createAction('[Settings] Enable Debug Mode');

export const disableDebugMode = createAction('[Settings] Disable Debug Mode');

export const toggleTestToolbar = createAction('[Settings] Toggle Test Toolbar');

export const switchEnv = createAction(
  '[Settings] Switch ENV',
  props<{ env: TargetEnv }>()
);

export const switchContentfulContext = createAction(
  '[Settings] Switch Contentful Context',
  props<{ contentfulContext: ContentfulContext }>()
);
